.report {
    height: calc(100% - 6rem);

    &__header {
        width: 100%;
        height: 6rem;
        background-color: $secondaryGreyLight;
        border-top-right-radius: $borderRadius;
        border-top-left-radius: $borderRadius;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;

        &-time {
            color: $primaryGrey;
            & > span:first-child {
                font-size: 1.3rem;
            }

            & > span:last-child {
                font-size: 1.3rem;
                font-weight: 300;
                margin-left: .6rem;
            }
        }

        &-name {
            font-size: 1.8rem;
            color: $textColor;
            font-weight: 600;
        }

        &-actions {
            height: 100%;
            display: flex;
            align-items: center;
            float: right;

            & > button, div {
                margin-left: 1rem;
            }
        }
    }

    &__content {
        height: 100%;
        width: 100%;
        padding: 1rem;
        overflow: auto;
    }

    &__filter {
        width: fit-content;
        background-color: $primaryGrey;
        color: $primaryWhite;
        font-size: 1.4rem;
        margin-bottom: 1rem;
        padding: .3rem 1rem;
    }
}