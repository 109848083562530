.errorPopup {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    &__text {
        font-size: 1.6rem;
        margin-top: 1rem;
        padding: .5rem 1.5rem 1.5rem 1.5rem;
        text-align: center;
    }

    &__header {
        background-color: $primaryRed;
    }
}