.inputImage {
    position: relative;
    &::-webkit-file-upload-button {
        display: none;
    }
    color: transparent;
    font-size: 1.2rem;
    border: 1px solid $primaryGreyLight;
    height: 20rem;
    border-radius: $borderRadius;
    overflow: hidden;

    &:hover {
        background-color: rgba($primaryGrey, .1);
        cursor: pointer;
    }

    &__label {
        position: absolute;
        left: 4%;
        top: -.5rem;
        font-size: 1.1rem;
        background-color: $primaryWhite;
        z-index: 2;
        padding: 0 4px;
        color: $primaryGrey
    }

    &__icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 8rem;
        color: $primaryGrey;
    }

    &__upload {
        font-size: 2rem;
        background-color: $primaryWhite;
        border-radius: 100rem;
        padding: 1rem;
        z-index: 10;

        &:hover {
            color: rgba($primaryGrey, .5);
        }
    }

    &__delete {
        font-size: 2rem;
        background-color: $primaryWhite;
        border-radius: 100rem;
        padding: 1rem;
        margin-top: .4rem;
        z-index: 10;

        &:hover {
            color: rgba($primaryGrey, .5);
        }
    }

    &__actions {
        color: $primaryGrey;
        position: absolute;
        top: 1rem;
        right: 1rem;
        height: 7.5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        z-index: 5;
    }

    &__input {
        display: none;
        opacity: 0;
        visibility: hidden;
    }

    &__imageBox {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        z-index: 5;
    }

    &__image {
        width: 100%;
        position: absolute;
    }

    &__imageDetail {
        height: 100%;
    }
}

.inputImage__selected {
    & > div > input {
        color: $primaryGrey;
    }
}
