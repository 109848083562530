.subpanelTable {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1rem;

    &__wrapper {
        width: 100%;
        padding: .5rem 1.5rem 1rem 1.5rem;
    }

    &__header {
        height: 3.5rem;
    }

    &__headerCell {
        font-size: 1.2rem;
        color: $textColor;
        font-weight: bold;
        padding: .4rem;
    }

    &__row {
        font-size: 1.2rem;
        height: 2.8rem;

        &:last-child > td {
			border-bottom: none;
		}

        &:hover > td:not(:last-child) {
            background-color: $markHoverColor;
        }
    }

    &__row:nth-of-type(odd) {
		background-color: $secondaryGrey;
	}

	&__row:nth-of-type(even) {
		background-color: $secondaryGreyLight;
	}

    &__deleteIcon {
        font-size: 1.4rem;
        cursor: pointer;
    }

    &__delete {
        background-color: $primaryWhite;
        text-align: center;
        min-width: 2rem;
    }
}