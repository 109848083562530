.listViewCheckbox {
    & > span {
        font-weight: 600;
    }

    &__check {
        color: $primaryGreen;
    }

    &__uncheck {
        color: $primaryRed;
    }
}
