.appHeader {
	height: 3rem;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-bottom: 1.5rem;

	&__client {
		margin-right: auto;
		height: 3rem;
		padding: 0 1rem;
		border: 1px solid $primaryColor;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #FFF;
		border-radius: $borderRadius;
		background-color: $primaryColor;
		font-weight: 600;
		box-shadow: $basicBoxShadow;

		@media (max-width: 900px) {
			margin-left: 6rem;
		}
	}

	&__badge > .MuiBadge-badge {
		right: 1.2rem !important;
		font-size: 1rem;
		cursor: pointer;
	}

	&__path {
		color: $primaryColor;
		text-decoration: none;
	}

	&__container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 24rem;
	}

	&__logo {
		height: 2.4rem;
		margin-top: 2px;
		margin-left: 10px;
	}

	&__box {
		display: flex;
		justify-content: space-between;
	}

	&__item {
		height: 3rem;
		width: 3rem;
		position: relative;
		border-radius: $borderRadius;
		margin-right: 1rem;
		box-shadow: $basicBoxShadow;
		background-color: $primaryWhite;
		color: $activeColor;

		&-active {
			background-color: $primaryColor !important;
			color: #FFF;
		}

		&:last-child {
			margin-right: 0;
		}

		&:hover {
			background-color: $primaryColor;
			color: #FFF;
			cursor: pointer;
		}

		&-iconBox {
			height: 100%;
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;

			& > span {
				font-size: 1.6rem;
			}
		}
	}

	&__itemLarge {
		width: auto;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0 .6rem;

		& > span {
			margin-left: .4rem;
			font-size: 1.6rem;
		}
	}

	&__logoutAs {
		background-color: $primaryViolet;
		color: $primaryWhite;
	}
}
