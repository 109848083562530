.repairPopup {
    &__content {
        padding: 1rem;

        &-load {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        height: 60vh;
        width: 80vh;
    }
}

.repairResult {
    & > span {
        margin-right: .5rem;
    }

    &__type {
        font-weight: 600;
    }

    &__success {
        color: $primaryGreen;
    }

    &__error {
        color: $primaryRed;
    }

    &__warning {
        color: $primaryOrange;
    }
}
