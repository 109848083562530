.lines {
    width: 100%;
    height: 100%;
    border-radius: $borderRadius;
    margin-top: 1.5rem;
    overflow: auto;
}

.linesClassic {
    background-color: $primaryWhite;
    box-shadow: $basicBoxShadow;
    border: 1px solid $primaryGreyLight;
}

.linesGroup {
    min-width: 80rem;
    &__groupData {
        width: 100%;
    }
}

.linesRow {
    z-index: 2;
    &__subProduct {
        padding-left: 2rem !important;
    }

    &__menu {
        cursor: pointer;
        transition: all .2s;

        &:hover {
            color: $textColor;
        }
    }

    &__icons {
        & > span {
            font-size: 1.4rem;
            color: $primaryGrey;
            cursor: pointer;
            transition: color .2s;
            margin-right: 1rem;
        }

        &-delete {
            cursor: pointer;
            &:hover {
                color: $primaryRed;
            }
        }

        &-dnd {
            &:hover {
                color: $textColor;
                cursor: grab;
            }
        }
    }

    & > td > div {
        min-width: 6rem;
    }

    &__readonly {
        border: 1px solid $readOnlyColor;
        color: $textColor;
        padding: .8rem;
        font-size: 1.2rem;
        border-radius: $borderRadius;
        cursor: not-allowed;
    }

    &__noEdit {
        border: 1px solid $primaryGreyLight;
        padding: .7rem .8rem .8rem 1rem;
        font-size: 1.2rem;
        border-radius: $borderRadius;
        color: $primaryBlack;
        cursor: text;
        width: 100%;

        &-select {
            cursor: pointer;
            position: relative;
            padding-right: 3.2rem;
        }

        &-selectArrow {
            color: $primaryGrey;
            font-size: .8rem;
            margin-top: .4rem;
            margin-right: .4rem;
            cursor: pointer;
            position: absolute;
            top: .8rem;
            right: .7rem;
        }

        &-relate {
            position: relative;
            & > .iconfas-search {
                padding: 1rem;
                font-size: 1.1rem;
                color: $textColor;
                cursor: pointer;
                color: $activeColor;
                position: absolute;
                top: 1px;
                right: 0;

                &:hover {
                    transform: scale(1.05);
                }
            }
        }
    }
}

.lineField {
    min-width: 5rem;

    &__relate {
        display: flex;
        align-items: center;

        &-image {
            margin-right: 1.5rem;
            font-size: 2rem;
            cursor: pointer;
        }

        &-file {
            margin-right: .6rem;
            font-size: 2rem;
            cursor: pointer;
        }
    }

    &__skeleton {
        width: 100%;
    }
}

.linesTotal {
    width: 100%;
    border-collapse: collapse;

    &__wrapper {
        padding: 0 1rem 1rem 1rem;
    }

    &__line {
        border-bottom: 1px solid $primaryGreyLight;
    }
}
