.relate {
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
    height: 3.25rem;
    position: relative;
    max-width: 50rem;

    @media (max-width: 1920px) {
        max-width: 40rem;
    }

    @media (max-width: 1700px) {
        max-width: 35rem;
    }

    @media (max-width: 750px) {
        max-width: 70rem;
    }

    & > div {
        padding-left: .6rem !important;
        width: 100%;
    }

    & > div > div {
        max-width: calc(100% - 5rem);
        margin: 0 1rem 0 0;
    }

    &__active {
        & > div > input {
            width: 0 !important;
            padding: .75rem 0 !important;
        }
    }

    &__boxActive > div > div > input {
        padding: .75rem 0;
    }

    &__searchIcon {
        padding: 1rem;
        font-size: 1.1rem;
        color: $textColor;
        cursor: pointer;
        color: $activeColor;
        position: absolute;
        top: 1px;
        right: 0;

        &:hover {
            transform: scale(1.05);
        }
    }

    &__link {
        font-size: 1.2rem;
        color: $linkColor;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    &__value {
        padding: 0 .5rem;
        width: 100%;
        border-radius: .4rem;
        // min-width: 16rem;

        &-active {
            background-color: $secondaryGreyDark;
            height: 2rem !important;

            & > input {
                display: none !important;
            }
        }
    }

    &__clearIcon {
        font-size: 1.2rem;
        margin-left: auto;
        margin-right: .2rem;
        margin-top: .1rem;
        cursor: pointer;
        padding-left: 1rem;
    }

    &__options {
        width: 100%;
        min-height: 4rem;
        max-height: 30rem;
        overflow: auto;
        position: absolute;
        left: 0;
        top: 5.2rem;
        background-color: $primaryWhite;
        z-index: 12;
        // border-radius: $borderRadius;
        box-shadow: $secondaryBoxShadow;
        padding: 1rem .5rem;
        border: 1px solid #FFF;

        &-item {
            padding: .5rem .2rem;
            font-size: 1.2rem;
            cursor: pointer;

            &--selected {
                background-color: $primaryColorLight;
                color: #333;
            }

            &:hover {
                background-color: $markHoverColor;
                color: $textColor;
            }

            &:not(:last-child) {
                border-bottom: 1px solid $secondaryGreyDark;
            }
        }

        &-itemSkeleton {
            margin: .5rem .2rem;
        }
    }
}
