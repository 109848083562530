.linesGroup {
    background-color: $primaryWhite;
    box-shadow: $basicBoxShadow;
    border: 1px solid $primaryGreyLight;
    border-radius: $borderRadius;
    z-index: 1;

    &__favoriteWrapper {
        width: 100%;
        background-color: $secondaryGrey;
        min-height: 1rem;
    }

    &__delete {
        margin-left: auto !important;
    }

    &__groupWrapper {
        &:not(:first-child) {
            margin-top: 1.5rem;
        }
    }

    &__dnd {
        cursor: grab;
        transition: all .2s;
        font-size: 1.4rem;
        margin-right: 1rem;

        &:hover {
            color: $textColor;
        }
    }

    &__load:not(:first-child) {
        margin-top: 1.5rem;
    }

    &__wrapper {
        padding: .5rem;
    }

    &__groupData {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 1rem 1rem 0 1rem;
        background-color: $secondaryGrey;
        border-top-right-radius: $borderRadius;
        border-top-left-radius: $borderRadius;
    }

    &__addGroup {
        margin: 1rem !important;
    }

    &__addRow {
        margin-top: .5rem !important;
    }

    &__table {
        padding: 1rem;
        width: 100%;
        border-collapse: collapse;

        & > thead > tr > th {
            padding: .3rem;
        }

        & > tbody > tr > td {
            padding: .3rem;
        }
    }
}

.groupInput {
    width: 12% !important;
    margin-right: 1rem;
    & > label {
        color: $textColor;
    }
    & > div {
        width: 100% !important;
        background-color: $primaryWhite;
    }
}

.groupTextArea {
    width: 20% !important;
    margin-left: 5rem;
    & > label {
        color: $textColor;
    }
    & > div {
        background-color: $primaryWhite;
    }
}

.discountSelect {
    width: 10rem !important;
    margin-right: 1rem;
    background-color: $primaryWhite;
}

.groupTotal {
    font-size: 1.2rem;
    color: $textColor;
    text-align: right;
    font-weight: 600;
    padding: .3rem;
    min-width: 15rem;
    vertical-align: top;

    &__weight {
        margin-top: 1rem;
    }
}

.linesGroupTotal {
    & > td {
        border-top: 1px solid $primaryGreyLight;
    }
}