.classicInput > .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
	border-color: $activeColor !important;

	> legend {
		margin-left: .7rem !important;
	}
}

.searchBox {
	border-radius: 0.7rem;
	width: 100%;
	box-shadow: $basicBoxShadow;

	&__container {
		float: left;
        text-align: left;
        position: relative;
		background-color: $primaryWhite;
		border-radius: $borderRadius;

		@media (max-width: 1200px) {
			width: 15rem;
		}
	}

	&__remove-icon {
		position: absolute;
		top: .8rem;
		right: 1rem;
		font-size: 1.5rem;
		cursor: pointer;
		color: $primaryColor;
	}

	&__search-icon {
		position: absolute;
		top: .8rem;
		right: 1rem;
		font-size: 1.5rem;
		color: $activeColor;
		cursor: pointer;
	}

	& p {
		font-size: 1.1rem;
	}

	& > label {
		font-size: 1.4rem;
		padding: 0 0.7rem;
		color: rgba($primaryGrey, 0.6) !important;
		background-color: $primaryWhite;

		& > span {
			color: $primaryRed;
		}

		&[data-shrink='false'] {
			margin-top: -.9rem;
			margin-left: -.7rem;
			font-size: 1.2rem;
		}
	}

	& > div > input {
		padding: 0.2rem 3rem 0.2rem 0.8rem;
		margin: 0.5rem 0rem 0.4rem 0rem;
		font-size: 1.2rem;
		color: $primaryGrey;
		width: 28rem;

		@media (max-width: 700px) {
			width: 12rem;
		}
	}

	& > div {
		border-radius: 0.7rem;
	}
}
