.listViewPrint {
    &__icon {
        font-size: 1.6rem;
        margin-right: .3rem;
    }

    & > div {
        color: $linkColor;
        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }
}