:root {
    --loader-size: 5rem;
}
.loader {
    width: var(--loader-size);
    height:var(--loader-size);
    border-radius: 50%;
    display: inline-block;
    border: 3px solid;
    border-color: $primaryColor $primaryColor transparent $primaryColor;
    box-sizing: border-box;
    animation: rotation 1.5s linear infinite;
    z-index: 10;
    text-align: center;
}
.loader::after,
.loader::before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border: 3px solid;
    border-color: transparent transparent $primaryColor $primaryColor;
    width: calc(var(--loader-size) - 15px);
    height: calc(var(--loader-size) - 15px);
    border-radius: 50%;
    box-sizing: border-box;
    animation: rotationBack 0.7s linear infinite;
    transform-origin: center center;
}
  .loader::before {
    width: calc(var(--loader-size) - 30px);
    height: calc(var(--loader-size) - 30px);
    border-color: $primaryGrey $primaryGrey transparent transparent;
    animation: rotation 2s linear infinite;
}

 @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes rotationBack {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(-360deg);
    }
}

.loadBox {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2rem;
}

.loadBoxCenter {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.loadBoxListViewPopup {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2rem;
    height: calc(100% - 3rem);
    width: 100%;
}