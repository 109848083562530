.reportFilters {
    width: 100%;
    padding: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;

    &__field {
        width: 30rem;
    }
}