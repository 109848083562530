.phoneBox {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__plus {
        font-size: 2rem;
        margin-top: -4px;
    }

    &__container {
        display: flex;
        align-items: flex-start;
        width: 100%;
    }

    & > div > div > div > div > .arrow {
        display: none;
    }

    & > div > input {
        margin-bottom: -1px;
    }

    & > .react-tel-input > .form-control  {
        border-radius: $borderRadius;
        // border-color: rgba($primaryGrey, 0.3);
        height: 3.2rem;
        width: 100%;
        font-size: 1.2rem;

        &:focus {
            border: 2px solid $primaryGreyLight !important;
        }
    }

    & > .react-tel-input > input {
        background-color: transparent;
        color: $textColor;
    }

    & > .react-tel-input > .flag-dropdown {
        background-color: transparent;
        border-top-left-radius: $borderRadius !important;
        border-bottom-left-radius: $borderRadius !important;

        & > div {
            border-top-left-radius: .6rem !important;
            border-bottom-left-radius: .6rem !important;
        }

        & > div:hover {
            background-color: $markHoverColor !important;
        }
    }
}

.phoneCode {
    width: 32.5%;
}

.phoneNumber {
    width: 60%;
}
