.listViewHeader {
    height: 5rem;
    font-weight: 600;
    color: $textColor;
    font-size: 1.2rem;
    width: 100%;
    position: sticky;
    top: 0;
    background-color: $primaryWhite;
    z-index: 1;
    display: table-row;
    text-align: left;

    &__columnName {
        & > span {
            font-size: 1.2rem;
            margin-right: 1rem;
            color: $primaryRed;
        }
    }

    &__checkboxCell {
        width: 1%;
        text-align: center;
        border-bottom: 1px solid $thirdGrey;

        & > .basicCheckbox {
            display: block;
            margin-top: 2.2rem;
        }

        & > .basicCheckbox > span {
            padding: 0;
        }
    }

    &__cell {
        display: table-cell;
        padding: .4rem;
        border-bottom: 1px solid $thirdGrey;
    }

    &__box {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        border-right: 1px solid $secondaryGreyDark;
        height: 4.2rem;
        padding-right: .4rem;
    }

    &__filterInput {
        width: 100%;
        margin-bottom: -.5rem !important;

        &-selectLabel {
            font-size: 1.2rem !important;
            margin-top: -.4rem !important;
            color: $textColor !important;
        }

        & > div > input, div {
            font-size: 1.2rem !important;
            padding: .2rem;
            color: $textColor;
        }
    }

    &__filterInput > label {
        font-size: 1.2rem;
        color: $textColor;
    }

    &__filter {
        width: 1.6rem;
        text-align: right;

        &-iconBox {
            // margin-top: 2.5rem;
            color: $primaryGrey;

            & > div {
                margin-bottom: 0 !important;
            }
        }

        &-checkbox {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            cursor: pointer;

            & > span {
                padding: .5rem;
            }

            & > p {
                margin-left: .2rem;
                font-weight: 400;
            }
        }

        &-icon {
            font-size: 1.6rem;
            min-height: 1.6rem;
            color: $primaryGrey;
        }

        &:hover {
            cursor: pointer;
        }
    }
}

.listViewHeader__filter-icon.iconfas-save {
    margin-bottom: .5rem;
    transition: all .2s;

    &:hover {
        color: $primaryGreen;
    }
}

.datetimeFilter {
    width: 100%;

    &__clear {
        font-size: 1rem;
        margin-left: 1rem;
        color: $primaryGrey;
        cursor: pointer;
    }

    &__box {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: -.5rem;
        width: 100%;
        padding: 0 1rem;
        margin-top: .6rem;

        & > span {
            margin-right: 1rem;
        }

        & > div > div > input {
            font-size: 1.2rem;
            color: $textColor;
        }
    }

    &__date {
        width: 100%;
    }

    &__date > div > div > button {
        padding: 0 8px 0 0;
    }
}

.relateFilter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 1rem .5rem 1rem;
    border-bottom: 1px solid rgba($primaryGrey, .7);
    margin-bottom: -.5rem;

    &__box {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
    }

    &__text {
        font-weight: 400;
    }

    &__icon {
        font-size: 1.3rem;
    }

    &__icon.iconfas-delete {
        margin-left: 1rem;
        cursor: pointer;
    }
}

.MuiSvgIcon-root {
    color: $primaryGrey;
}