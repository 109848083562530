.subpanel {
    overflow: hidden;
    color: $textColor;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: $primaryWhite;
    border-radius: $borderRadius;

    &__actionBox {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 70%;
    }

    &__headerBox {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 30%;
    }

    &__header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 4rem;
        width: 100%;
        font-weight: 500;
    }

    &__iconBox {
        width: 4rem;
        height: 4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $primaryWhite;
        background-color: $secondaryGrey;

        & > span {
            font-size: 1.6rem;
        }
    }

    &__iconRotate {
        transition: all .2s;
        transform: rotate(180deg);
    }

    &__statusControl {
        color: $textColor;
        margin-left: .75rem;

        & > span {
            transition: all .2s;
            font-size: 2.2rem;
            cursor: pointer;
        }
    }

    &__title {
        margin-left: 2rem;
        font-size: 1.4rem;
        width: 30rem;
    }

    &__box {
        background-color: $primaryWhite;
        height: 100%;
    }

    &__button {
        font-size: 1.6rem;
        min-width: 1.6rem;
        margin: .75rem;
        cursor: pointer;
        color: $primaryGrey;
    }

    &__pagination {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}