.fileField {
    &__input {
        display: none;
    }

    &__file {
        font-size: 1.2rem;
        color: $linkColor;
        cursor: pointer;
        width: 70%;
    }

    &__box {
        width: 100%;
        height: 3.2rem;
        border-radius: $borderRadius;
        border: 1px solid $primaryGreyLight;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: .8rem;
    }

    &__label {
        font-size: 1.2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        padding: 0 .8rem .2rem 0;
        cursor: pointer;
        margin-bottom: -1px;
    }

    &__icon {
        color: $primaryGrey;
        font-size: 1.6rem;
        padding: 4px;
        border-radius: $borderRadius;
        margin-bottom: -2px;
        transition: all .2s;
        margin-left: 4px;

        &:hover {
            background-color: $primaryColor;
            color: $primaryWhite;
            cursor: pointer;
        }

        &-delete:hover {
            background-color: $primaryRed;
            cursor: pointer;
        }
    }
}

.noValue {
    width: 100%;
    margin-top: -1px;

    & > label {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0px !important;

        & > .fileField__icon {
            float: right;
        }
    }
}
