.calendarEvent {
    padding: .5rem;
    border-radius: $borderRadius;
    position: relative;
    height: 100%;

    @media (max-width: 900px) {
        font-size: 1rem;
    }

    &__header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    &__text {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__info {
         // display: none;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &-time {
            margin-right: .5rem;
        }

        &:first-child {
            margin-right: .5rem;
        }
    }

    &__icon {
        margin-right: 1rem;
    }
}

.fc-event {
    border-radius: .7rem !important;
}