.creditCheck {
    padding: 1rem;
    width: 100%;
    max-width: 80rem;
    min-width: 80rem;
    max-height: 90rem;
    overflow: auto;

    &__companyStatus {
        width: 1.2rem;
        height: 1.2rem;
        border-radius: 10rem;
        margin-left: .6rem;

        &-1 {
            background-color: $primaryGreen;
        }

        &-2 {
            background-color: $primaryYellow;
        }

        &-3 {
            background-color: $primaryRed;
        }
    }

    &__content {
        &-header {
            font-size: 1.6rem;
            font-weight: 400;
            margin-top: 2rem;
            margin-bottom: .6rem;
            color: $textColor;
        }
    }

    &__table {
        width: 100%;
        border-collapse: collapse;

        & > thead > tr > th {
            text-align: left;
        }

        & > tbody > tr > td {
            padding: .2rem;
        }

        & > tbody {
            & > tr:nth-of-type(odd) {
                background-color: $secondaryGrey;
            }

            & > tr:nth-of-type(even) {
                background-color: $secondaryGreyLight;
            }
        }
    }

    &__markText {
        font-weight: 600;
        margin-right: .6rem;
    }

    &__header {
        color: $textColor;
        font-size: 1.6rem;

        &-text {
            font-size: 2.4rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-weight: 600;
            margin-bottom: 2rem;
        }

        &-box {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &-icon {
            transition: all .2s;
            margin-left: 1rem;

            &:hover {
                cursor: pointer;
                color: $primaryColor;
            }
        }
    }

    &__status {
        height: 2rem;
        width: 2rem;
        border-radius: 10rem;
        display: block;
        float: left;
        margin-right: 1rem;

        &-1 {
            background-color: $primaryGreen;
        }

        &-2 {
            background-color: $primaryYellow;
        }

        &-3 {
            background-color: $primaryRed;
        }
    }
}
