.linesGroupHeader {
    font-size: 1.2rem;
    color: $textColor;

    &__icons {
        width: 2.4rem;
    }

    & > tr > th {
        font-weight: 500;
        text-align: left;
        padding-left: 1rem;
        padding-bottom: .5rem;
    }
}
