.notifications {
    z-index: 100;
    position: relative;
    right: 37rem;
    top: 1.5rem;
    background-color: $primaryWhite;
    color: $textColor;
    width: 40rem;
    height: 60rem;
    border-radius: $borderRadius;
    box-shadow: $basicBoxShadow;
    cursor: default;
    overflow: hidden;

    @media (max-width: 550px) {
		right: 27rem;
        width: 34rem;
	}

    &__line {
        height: 1rem;
        width: 100%;
        background-color: $primaryColor;
        border-top-left-radius: $borderRadius;
        border-top-right-radius: $borderRadius;
    }

    &__header {
        width: 100%;
        padding: 1rem .5rem 1rem 1rem;
        margin-bottom: 1rem;
        background-color: $primaryColor;

        &-box {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &-text {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 1.6rem;
            font-weight: 600;
            color: #FFF;

            & > span {
                margin-right: 1rem;
                color: $primaryWhite;
            }
        }

        &-icon {
            cursor: pointer;
            margin-left: 1rem;
            color: #FFF;
            padding: 4px;
            border-radius: $borderRadius;
            transition: all .2s;
            margin-bottom: -2px;

            &:hover {
                background-color: $primaryWhite;
                color: $primaryColor;
            }
        }
    }

    &__counter {
        height: 2rem;
        min-width: 2rem;
        padding: 0 .4rem;
        border-radius: 10rem;
        color: $primaryGrey;
        font-size: 1.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__tab.Mui-selected {
        color: $activeColor !important;

        & > div {
            border: 1px solid $activeColor;
            background-color: $activeColor;
            color: $primaryWhite;
        }
    }

    &__tab {
        min-height: 40px !important;
        padding: .6rem 1rem !important;
        color: $primaryGrey;
        font-size: 1.2rem !important;
        text-transform: capitalize !important;

        & > div {
            color: $primaryGrey;
            border: 1px solid $primaryGrey;
        }

        &-divider {
            width: 1px;
            height: 2.5rem;
            background-color: $primaryGrey;
            margin-top: .7rem;
        }
    }

    &__tabs > div {
        max-height: 40px;
        border-bottom: 1px solid $primaryGrey;
    }

    &__tabs > div > div > button {
        color: $textColor;
    }

    &__allAction {
        cursor: pointer;
        color: $linkColor;
        text-align: center;
        transition: all .2s;
        width: fit-content;
        font-size: 1.2rem;
        border-radius: $borderRadius;
        padding: .2rem 0;
        margin-left: auto;
        margin-right: 1rem;
        min-height: 2rem;

        &:hover {
            text-decoration: underline;
        }
    }
}

.notifications__loader {
    width: 1rem;
    height: 1rem;
    border: 2px solid $primaryWhite;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

.notifications__loaderBlack {
    width: 1rem;
    height: 1rem;
    border: 2px solid $primaryGrey;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.notificationsList {
    &__content {
        height: 48rem;
        overflow: auto;
    }

    &__noItems {
        text-align: center;
        font-size: 1.2rem;
    }
}

.notificationItem {
    padding: 1rem;
    border-bottom: 1px solid $primaryGreyLight;
    max-height: 7rem;
    overflow: hidden;
    transition: all .3s;
    position: relative;

    &__header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    &__link {
        font-size: 1.2rem;
        color: $linkColor;
        cursor: pointer;
        margin-left: .4rem;

        & > span {
            font-size: 1.4rem;
            margin-right: .2rem;
        }
    }

    &__open {
        max-height: 30rem;
    }

    &:hover {
        background-color: $markHoverColor;
        cursor: pointer;
    }

    &__iconBox{
        min-width: 4rem;
        height: 4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $primaryGrey;
        border-radius: 100rem;
    }

    &__iconBox {
        background-color: $primaryGrey;
        & > div {
            margin-top: -1px;
        }
    }

    &__iconBox.success {
        background-color: $primaryGreen;
    }

    &__iconBox.warning {
        background-color: $primaryOrange;
        & > div {
            margin-top: -1px;
        }
    }

    &__iconBox.error {
        background-color: $primaryRed;
    }

    &__icon {
        font-size: 2rem;
        color: $primaryWhite;
    }

    &__main {
        padding-left: 1rem;
    }

    &__title {
        font-weight: 600;
        color: $textColor;
        text-wrap: wrap;
        max-width: 28rem;
        font-size: 1.2rem;
    }

    &__byUserBox {
        font-size: 1.1rem;
        margin-top: .6rem;
    }

    &__date {
        color: $primaryGrey;
        margin-left: .5rem;
    }

    &__user {
        color: $linkColor;
    }

    &__message {
        font-size: 1.2rem;
        margin-top: 1.2rem;
    }

    &__time {
        position: absolute;
        top: .8rem;
        right: 1rem;
        font-size: 1.2rem;
    }

    &__archive {
        font-size: 1.4rem;
        position: absolute;
        top: 2.8rem;
        right: 1rem;
        z-index: 110;
        cursor: pointer;
        transition: all .2s;
        padding: 4px;
        border-radius: $borderRadius;

        &:hover {
            background-color: $primaryColor;
            color: $primaryWhite;
        }
    }
}

.notificationsSettings {
    &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 2rem;
    }
}
