.selectField {
	border-radius: 0.7rem;
	width: 100%;

	&__search {
		padding: 1rem;
	}

	&__readonly > svg {
		display: none !important;
	}

	&__value {
		text-align: left;
		width: min-content;
		color: $textColor;
	}

	& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
		border-color: $primaryGreyLight !important;
	}

	&__item {
		font-size: 1.2rem !important;
		min-height: 3rem !important;
		text-align: left;
		color: $textColor !important;

		&-active {
			color: $primaryWhite !important;
		}

		&-inactive {
			color: $primaryGrey;
		}

		& > span {
			padding: 0px;
			margin-right: 1rem;
		}
	}

	&__emptySpan {
		width: 1.5rem;
		margin-right: 1rem;
	}

	&__item.Mui-selected {
		color: $primaryWhite;
	}

	&__icon {
		font-size: 1.2rem;
		margin-right: .6rem;
	}

	// & > label {
	// 	background-color: $primaryWhite;
	// 	padding-right: 0.8rem;
	// 	padding-left: 0.8rem;
	// 	color: rgba($primaryGrey, 0.6) !important;

	// 	& > span {
	// 		color: $primaryRed;
	// 	}

	// 	&[data-shrink='false'] {
	// 		margin-top: -.75rem;
	// 		font-size: 1.2rem;
	// 		margin-left: -.8rem;
	// 	}
	// }

	& > div > div {
		padding: 0.5rem 0.5rem 0.5rem 0.8rem;
		padding-top: 1rem;
		font-size: 1.2rem;
	}

	& > div {
		border-radius: 0.7rem;
	}
}

.simpleSelect {
	width: 100%;
}
