.leftMenu {
	min-width: 26rem;
	position: relative;
	transition: all 0.3s;
	margin: 1.5rem 0rem 1.5rem 1.5rem;
	border-radius: $borderRadius;
	background-color: $primaryColor;
	box-shadow: $basicBoxShadow;
	overflow: hidden;
	height: calc(100vh - 3rem);
	z-index: 15;

	@media (max-width: 900px) {
		position: absolute;
		left: 0;
		top: 0;
	}

	&__itemsBox {
		padding: 0.8rem;
		overflow-y: auto;
		height: calc(100% - 5rem);
	}

	&__logo {
		height: 5rem;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		background-color: $primaryGreyDark;
		padding-left: 1.8rem;
		border-top-right-radius: 0.7rem;
		border-top-left-radius: 0.7rem;

		&-image {
			height: 2.8rem;
			padding-left: 2rem;
		}
	}

	&__version {
		margin-left: auto;
		margin-right: 1.5rem;
		color: $primaryGreyLight;

		&__hidden {
			display: none;
		}
	}

	&__close {
		min-width: 5.2rem;
		max-width: 5.2rem;

		@media (max-width: 900px) {
			overflow: hidden;
			height: 3rem;
			width: 8rem;

			& > .leftMenu__logo {
				padding: 0;
				height: 3rem;
				display: flex;
				justify-content: center;
				align-items: center;
			}

			& > .leftMenu__itemsBox {
				display: none;
			}
		}

		& > div > img {
			display: none;
		}
	}

	&__submenuIcon {
		margin-left: auto;
		z-index: 5;
		transition: all 0.3s;
		color: #FFF;

		&-close {
			transform: rotate(90deg);
		}

		&-open {
			transform: rotate(180deg);
		}
	}

	&__hamburger {
		color: $primaryWhite;

		&:hover {
			cursor: pointer;
		}
	}

	&__hamburger {
		color: $primaryWhite;
		width: 21px;
		height: 20px;
		cursor: pointer;

		& > div {
			background-color: #FFF;
		}

		&:hover &__p1 {
			width: 21px;
			opacity: 1;
		}

		&:hover &__p2 {
			width: 21px;
			opacity: 1;
		}

		&:hover &__p3 {
			width: 21px;
			opacity: 1;
		}

		&__p1 {
			float: left;
			width: 10px;
			height: 2px;
			clear: both;
			margin-top: 3px;
			background-color: $primaryWhite;
			opacity: 0.5;
			transition: all 200ms linear;
		}

		&__p2 {
			float: left;
			width: 21px;
			height: 2px;
			clear: both;
			margin-top: 4px;
			background-color: $primaryWhite;
			opacity: 0.5;
			transition: all 200ms linear;
		}

		&__p3 {
			float: left;
			width: 16px;
			height: 2px;
			clear: both;
			margin-top: 4px;
			background-color: $primaryWhite;
			opacity: 0.5;
			transition: all 200ms linear;
		}
	}

	&__module {
		padding-right: 7px;
		width: 100%;
		overflow: hidden;
		height: 4rem;
		padding-left: 0.8rem;
		display: flex;
		align-items: center;
		text-decoration: none;
		color: $primaryWhite;
		background-size: 200% 100%;
		transition: all 0.3s ease;
		border-radius: $borderRadius;
		margin-top: 0.5rem;
		&:first-child {
			border-top-right-radius: 0.7rem;
			border-top-left-radius: 0.7rem;
			margin-top: 0;
		}
		&-active {
			background-color: $primaryColorDark;
		}
		&:hover {
			background-color: $primaryColorHover;
			cursor: pointer;
		}
		&-icon {
			margin-right: 1.3rem;
			font-size: 2rem;
		}

		&-name {
			font-weight: bold;
			color: #FFF;
		}
	}
}

.subMenuItem {
	height: 3rem;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-left: 2rem;

	&__closeMenu {
		color: #FFF;
		border-radius: $borderRadius;
		height: 3rem;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 0.5rem;
		font-size: 1.4rem;

		&:hover {
			background-color: $primaryColorHover;
			cursor: pointer;
		}
	}

	&__active {
		margin-top: 0.5rem;
	}

	&__link {
		padding-right: 7px;
		width: 85%;
		height: 100%;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		text-decoration: none;
		color: $primaryWhite;
		background-size: 200% 100%;
		transition: all 0.3s ease;
		font-size: 1.4rem;
		border-radius: $borderRadius;
		margin-left: 1rem;
		padding: 0.5rem 0.5rem 0.5rem 1rem;
		color: #FFF;

		&:hover {
			background-color: $primaryColorHover;
			cursor: pointer;
		}
	}

	&__line {
		height: 100%;
		width: 1px;
		background-color: #FFF;
		margin-right: 2rem;
	}

	&-active {
		background-color: $primaryColorDark;
	}

	&-icon {
		margin-right: 1.5rem;
		font-size: 1.4rem;
	}
}
