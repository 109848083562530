.dateField {
    position: relative;
    width: 100%;
    border-color: $primaryGreyLight !important;

    & > .Mui-selected {
        background-color: $primaryColor !important;
    }

    &__error {
        & .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
            border-color: $primaryRed !important;
        }
    }

    &__outline {
        & .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
            border-color: $primaryGreyLight !important;
        }
    }

    &__tooltip {
        margin-right: 2.5rem;
        z-index: 3;
    }

    &__icon {
        position: absolute;
        top: .75rem;
        right: 1rem;
        font-size: 1.6rem;
        z-index: 2;

        &:hover {
            cursor: pointer;
        }
    }

    &__datePicker {
        z-index: 1;
        width: 100%;
        margin-left: 2rem;

        & > label {
            color: rgba($primaryGrey, 0.6) !important;
            font-size: 1.4rem;
            background-color: $primaryWhite;
		    padding-right: 0.8rem;

            &[data-shrink='false'] {
                margin-top: -0.75rem;
                font-size: 1.2rem;
            }
        }

        & > div {
            padding: .75rem 1.4rem .75rem .8rem !important;
            border-radius: 0.7rem !important;
            border-color: $primaryGrey !important;
            color: $primaryGrey;
        }

        & > div > input {
            padding: 0;
            font-size: 1.2rem;
        }

        & > div > div > button {
            color: $primaryGrey;
        }
    }
}

.MuiMultiSectionDigitalClockSection-root::-webkit-scrollbar {
    width: .7rem;
}

.MuiMultiSectionDigitalClockSection-root::after {
    display: none !important;
}

.MuiMultiSectionDigitalClockSection-item {
    font-size: 1.2rem !important;
    padding: .4rem .8rem !important;
}

.MuiPickersDay-dayWithMargin {
    font-size: 1.2rem !important;
    color: $primaryBlack !important;
}

.MuiDayCalendar-weekDayLabel {
    font-size: 1.4rem !important;
    color: $primaryGrey !important;
}

.MuiPickersCalendarHeader-labelContainer {
    font-size: 1.4rem !important;
}

.MuiDayCalendar-weekContainer > .Mui-selected {
    color: $primaryWhite !important;
    background-color: $primaryColor !important;
}

.MuiPickersToolbar-root > .MuiTypography-root {
    display: none;
}

.MuiDialogActions-root > .MuiButton-textPrimary {
    font-size: 1.2rem;
}

.MuiDayCalendar-weekNumber {
    font-size: 1rem !important;
    color: $primaryGrey !important;
}

.MuiMenuItem-gutters.Mui-selected {
    background-color: $primaryColor !important;
}

.MuiDateCalendar-root {
    background-color: $primaryWhite !important;
}

.MuiPickersCalendarHeader-labelContainer {
    color: $textColor !important;
}

.MuiPickersArrowSwitcher-button > svg {
    color: $textColor !important;
}

.MuiPickersCalendarHeader-switchViewButton > svg {
    color: $textColor !important;
}

.MuiPickersYear-yearButton {
    color: $textColor !important;
}
