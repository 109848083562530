.filtersSelect {
    width: 59.5rem;
    margin-right: 1.5rem !important;
    box-shadow: $basicBoxShadow;
    border-radius: .7rem !important;

    @media (max-width: 1200px) {
        width: 28rem;
    }

    & > div > div > .filtersSelect__item-delete {
        display: none;
    }

    &__item {
        font-size: 1.2rem !important;
        padding: 0 !important;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: $textColor !important;

        &-delete {
            font-size: 1.4rem;
            padding: .6rem;

            &:hover {
                color: $primaryRed;
            }
        }

        &-name {
            width: 100%;
            text-align: left;
            padding: .6rem 0 .6rem .6rem;
        }
    }

    & > div {
        background-color: $primaryWhite;
        border-radius: .7rem !important;
    }

    & > div > input {
		color: $primaryGrey;
    }

    & > div > div {
        padding: 0.2rem 3rem 0.2rem 0.8rem;
		margin: 0.7rem 0rem 0.2rem 0rem;
        font-size: 1.2rem !important;
        color: $primaryGrey !important;
    }
}

.filtersSelect__item.Mui-selected {
    color: $textColor !important;
}

.classicInput > .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
	> legend {
		margin-left: .7rem !important;
	}
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiMenu-paper.MuiPopover-paper.MuiMenu-paper {
    background-color: $primaryWhite !important;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl {
    & > svg {
        color: $textColor !important;
    }
}