.favoriteProducts {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 1rem 1rem 0 1rem;

    &__item {
        // flex: 1 1 100%;
        margin-right: 1rem;
        color: $textColor;
        border-radius: 10rem;
        padding: 0 .6rem;
        cursor: pointer;
        transition: background-color .3s;
        margin-bottom: 1rem;

        &:hover {
            background-color: rgba(var(--color-rgb), 1) !important;
        }
    }
}