@font-face {
	font-family: 'Inter';
	src: url('./assets/fonts/Inter-Thin.ttf') format('woff2');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Inter';
	src: url('./assets/fonts/Inter-ExtraLight.ttf') format('woff2');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'Inter';
	src: url('./assets/fonts/Inter-Light.ttf') format('woff2');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Inter';
	src: url('./assets/fonts/Inter-Regular.ttf') format('woff2');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Inter';
	src: url('./assets/fonts/Inter-Medium.ttf') format('woff2');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Inter';
	src: url('./assets/fonts/Inter-SemiBold.ttf') format('woff2');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Inter';
	src: url('./assets/fonts/Inter-Bold.ttf') format('woff2');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Inter';
	src: url('./assets/fonts/Inter-ExtraBold.ttf') format('woff2');
	font-weight: 800;
	font-style: normal;
}

/* Cabin Regular */
@font-face {
    font-family: 'Cabin';
    src: url('./assets/fonts/Cabin-Regular.ttf') format('woff2');
    font-weight: 400;
    font-style: normal;
}

/* Cabin Italic */
@font-face {
    font-family: 'Cabin';
    src: url('./assets/fonts/Cabin-Italic.ttf') format('woff2');
    font-weight: 400;
    font-style: italic;
}

/* Cabin Medium */
@font-face {
    font-family: 'Cabin';
    src: url('./assets/fonts/Cabin-Medium.ttf') format('woff2');
    font-weight: 500;
    font-style: normal;
}

/* Cabin Medium Italic */
@font-face {
    font-family: 'Cabin';
    src: url('./assets/fonts/Cabin-MediumItalic.ttf') format('woff2');
    font-weight: 500;
    font-style: italic;
}

/* Cabin SemiBold */
@font-face {
    font-family: 'Cabin';
    src: url('./assets/fonts/Cabin-SemiBold.ttf') format('woff2');
    font-weight: 600;
    font-style: normal;
}

/* Cabin SemiBold Italic */
@font-face {
    font-family: 'Cabin';
    src: url('./assets/fonts/Cabin-SemiBoldItalic.ttf') format('woff2');
    font-weight: 600;
    font-style: italic;
}

/* Cabin Bold */
@font-face {
    font-family: 'Cabin';
    src: url('./assets/fonts/Cabin-Bold.ttf') format('woff2');
    font-weight: 700;
    font-style: normal;
}

/* Cabin Bold Italic */
@font-face {
    font-family: 'Cabin';
    src: url('./assets/fonts/Cabin-BoldItalic.ttf') format('woff2');
    font-weight: 700;
    font-style: italic;
}

* {
	margin: 0;
	padding: 0;
}

*,
*::before,
*::after {
	box-sizing: inherit;
}

html,
body,
#root {
	height: 100%;
}

html {
	box-sizing: border-box;
	font-size: 62.5%; //1rem = 10px
}

body {
	font-family: 'FontAwesome', 'Cabin', 'Roboto', sans-serif;
	font-size: 1.4rem;
	color: $primaryGrey;

	div[role=tooltip] {
		font-size: 1.4rem !important;
	}
}

#app {
	height: 100%;
	width: 100%;
	position: relative;
}

.app {
	&__content {
		display: flex;
		height: 100%;
		overflow: hidden;
		background-color: var(--secondary-grey);

		@media (max-width: 900px) {
			display: block;
		}
	}

	&__main {
		padding: 1.5rem;
		height: calc(100% - 4.5rem);
		width: calc(100% - 27rem);
		position: relative;

		&-dashboard {
			padding: 0;

			& > .appHeader {
				padding: 1.5rem 1.5rem 0 1.5rem;
				height: 4.5rem;
			}

			& > .dashboard > .actionButtons {
				padding: 0 1.5rem;
			}
		}

		&-menuClose {
			width: 100%;
		}

		@media (max-width: 900px) {
			width: 100%;
		}
	}

	&__toast {
		position: absolute;
		bottom: 5rem;
		left: 5rem;
		z-index: 10;
	}

	&__loginAs {
		border: 5px solid $primaryViolet;
	}
}

.loginAs {
	border-bottom-right-radius: $borderRadius;
	border-bottom-left-radius: $borderRadius;
	position: absolute;
	left: 50%;
	padding: .4rem .6rem;
	transform: translateX(-50%);
	background-color: $primaryViolet;
	color: $primaryWhite;
	z-index: 1;
}


.debugName {
	color: $primaryYellow;
	margin-left: .4rem;
}

.debugType {
	color: $primaryOrange;
}

.loadingContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
}

.inactiveIcon {
	color: $primaryGreyLight;
	cursor: default !important;
}

.toaster {
	margin-top: 3.9rem;
}

.linkText {
	color: --link-color !important;
	cursor: pointer;

	&:hover {
		text-decoration: underline;
	}
}

.iconSmall {
	font-size: 1.5rem;
}

.hidden {
	display: none;
	opacity: 0;
}

::-webkit-scrollbar-track {
	border-radius: 1rem;
	background-color: transparent;
}

::-webkit-scrollbar {
	width: 1rem;
	height: 1rem;
	background-color: transparent;
}

::-webkit-scrollbar-thumb {
	border-radius: 1rem;
	background-color: $primaryGreyLight;
}

.MuiTooltip-popper > div {
	background-color: transparent;
	padding: 0 !important;
}

.MuiInputBase-input {
	font-size: 1.2rem !important;
}

.MuiTabs-indicator {
	height: 3px !important;
}

.MuiSkeleton-root {
	border-radius: $borderRadius !important;
}

.basicTabs {
	height: 3.5rem !important;
	min-height: 3.5rem !important;

	& > div > span {
		background-color: $primaryColor !important;
	}

	& > div > div {
		height: 3.5rem;
	}

	& > div > div > button {
		padding: 0 !important;
		min-height: 3.5rem;
		font-size: 1.4rem;
		text-transform: capitalize;
		color: $primaryColor !important;

		& > span {
			height: 3.5rem;
		}
	}
}
