.popup {
    top: 35%;
    left: 50%;
    transform: translateX(-50%) translateY(-30%);
    position: absolute;
    background-color: $primaryWhite;
    min-width: 50rem;
    min-height: 15rem;
    z-index: 100;
    box-shadow: $basicBoxShadow;
    border-radius: $borderRadius;
    animation-name: popupShow;
    animation-duration: .5s;

    @media (max-width: 700px) {
		min-width: 80%;
    }

    &__overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.3);
        z-index: 99;
    }

    &__header {
        width: 100%;
        background-color: $primaryColor;
        height: 4rem;
        padding: 1rem;
        font-size: 1.6rem;
        border-top-right-radius: .6rem;
        border-top-left-radius: .6rem;
        font-weight: 600;
        color: #FFF;
        display: flex;
        justify-content: space-between;
        align-items: center;

        & > span {
            font-size: 2.4rem;
            font-weight: lighter;
            transition: all .2s;
            cursor: pointer;

            &:hover {
                color: $lightRed;
            }
        }

        &-icon {
            margin-right: 1rem;
        }
    }
}

@keyframes popupShow {
    from {
        opacity: 0;
        top: 27.5%;
    }

    to {
        opacity: 1;
        top: 35%;
    }
}

.simpleInputPopupLabel {
    margin-right: 2rem;
}
