.saveFilterPopup {
    &__content {
        padding: 2rem;
    }

    &__input {
        width: 100%;
        font-size: 1.2rem;

        & > p {
            font-size: 1.1rem;
        }

        & > div > input {
            font-size: 1.4rem !important;
            color: $textColor;
        }
    }

    &__selector {
        margin-top: 1rem;
    }

    &__switch {
        margin-top: 1rem;
    }
}