.radioGroup {
	&__item {
		height: 2rem;

		& > span:last-child {
			font-size: 1.2rem !important;
			color: rgba($primaryGrey, 0.8) !important;
		}
	}

	&__label {
		font-size: 1.2rem !important;
		font-weight: bold !important;
		color: $textColor !important;
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
		background-color: rgba($primaryColor, .2);
		padding: 0.3rem 0.5rem;
		border-radius: $borderRadius;
		//box-shadow: $basicBoxShadow;
		border: 1px solid $primaryColor;
	}
}
