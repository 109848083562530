.listPagination {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    padding: 0 1rem;
    font-size: 1.2rem;
    height: 2rem;

    &__disabled {
        color: $primaryGreyLight;
    }

    &__recordRange {
        margin-right: 1rem;
        margin-top: -.2rem;
    }

    &__page {
        &-icon {
            font-size: 1.4rem;
            cursor: pointer;
            margin: 0 .4rem;
        }

        &-count {
            margin: 0 .5rem;
            position: relative;
            top: - .2rem;
        }

        &-empty {
            margin-right: 1.6rem;
        }
    }
}
