.usersTreeNode {
    &__container {
        width: 26rem; // 13rem * 2
        height: 7.5rem; // 5rem * 2
        background-color: $secondaryGrey;
        box-sizing: border-box;
        border-radius: 8px; // 4px * 2
        overflow: hidden;
        display: flex;
        box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.15); // (4px * 2) and (1px * 2)
        position: relative;
    }

    &__content {
        padding: 4px; // 2px * 2
        margin-left: 10px; // 5px * 2
        width: 100%;
    }

    &__stripe {
        height: 100%;
        width: 6px; // 3px * 2
    }

    &__name {
        font-size: 12px; // 6px * 2
        font-weight: bold;
        color: $linkColor;

        & > div {
            margin-top: .4rem; // 6px * 2
        }

        & > div > span {
            font-size: 12px; // 6px * 2
            margin-right: 4px; // 2px * 2
        }
    }

    &__role {
        font-size: 10px; // 5px * 2
        border-radius: 40px; // 20px * 2
        display: flex;
        justify-content: center;
        align-items: center;
        color: $primaryWhite;
        width: max-content;
        padding: 2px 4px; // (1px * 2) and (2px * 2)
        position: absolute;
        top: 6rem; // 3rem * 2
        right: 1rem; // .5rem * 2
        margin-top: -1.2rem;
    }

    &__icon {
        width: 3rem; // 2.5rem * 2
        height: 3rem; // 2.5rem * 2
        color: $primaryWhite;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 200rem; // 100rem * 2
        font-size: 1.6rem; // 1.6rem * 2
        background-color: $primaryGreyLight;
        position: absolute;
        top: 8px; // 5px * 2
        right: 8px; // 5px * 2
    }

    &__photo {
        scale: .08;
    }

    &__photoBox {
        height: 3rem;
        width: 3rem;
        overflow: hidden;
        border-radius: 100rem;
        position: absolute;
        top: 8px; // 5px * 2
        right: 8px; // 5px * 2
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__contact {
        font-size: 10px; // 5px * 2
        height: 3rem; // 1.5rem * 2
        margin-top: .8rem; // .8rem * 2

        &-box {
            display: flex;
            align-items: center;
            &:not(:first-child) {
                margin-top: 2px; // 1px * 2
            }
        }

       &-icon {
            font-size: 16px; // 8px * 2
            margin-right: 6px; // 3px * 2
       }

       &-phone {
            color: $linkColor;
            text-decoration: none;
       }

    }

    &__company {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        padding: 0 1rem;
    }

    &__companyName {
        font-size: 2.4rem; // 1.2rem * 2
        font-weight: 600;
        color: $textColor;
    }

    &__companyIcon {
        color: $textColor;
        font-size: 3rem;
    }
}

.rd3t-link {
    stroke: $primaryColor !important;
}
