.detailFormPopup {
    &__content {
        padding: 1rem;
        max-height: 70vh;
        // overflow: auto;

        & > .detail {
            overflow: visible !important;
        }
    }
}