.calendarContent {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 100%;

	@media (max-width: 900px) {
		height: calc(100% - 9rem);
	}
}

.calendarWrapper {
	height: 100%;
	width: 100%;
}

.calendar {
	background-color: $primaryWhite;
	width: 75%;
	height: 100%;
	overflow: auto;
	border-radius: 0.7rem;
	padding: 1rem;
	box-shadow: $basicBoxShadow;
	position: relative;

	@media (max-width: 900px) {
		width: 100% !important;
	}

	&__actionBox  > .actionButtons {
		width: 20rem;
		display: flex;
		align-items: center;
	}

	&__actionBox {
		@media (max-width: 900px) {
			justify-content: center;
			flex-direction: column;
		}
	}

	&__dateSelector {
		display: none !important;
	}

	&__actionButtons {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: calc(12rem + 25%);
		margin-left: 1.5rem;

		@media (max-width: 900px) {
			justify-content: center;
			margin-right: 0;
			margin-left: 9.5rem;
			margin-top: 1.5rem;
		}

		& > button {
			margin-right: 1.5rem;
			color: $primaryColor;
			text-transform: capitalize;
		}

		&-dateButton {
			min-width: 12rem;
		}

		&-invisibleButton {
			width: 6.4rem;
			opacity: 0;
			margin-left: 1.5rem;
		}

		&-today {
			@media (max-width: 900px) {
				margin-left: 1.8rem !important;
			}
		}
	}

	&__load > .loader {
		position: absolute;
		top: 45%;
		right: 50%;
	}
}

.calMonthView {
	height: 100%;
	text-align: center;

	&__table {
		height: calc(100% - 4rem);
		width: 100%;
	}

	&__header {
		height: 5rem;

		&-cell {
			text-align: center;
			font-size: 1.6rem;
		}
	}

	&__weekNumber {
		text-align: center;
	}

	&__cell {
		border: 1px solid $secondaryGrey;
		position: relative;
		width: calc(98% / 7);
		min-height: calc(100% / 6);

		&-date {
			position: absolute;
			top: 1rem;
			right: 1rem;
			height: 3.5rem;
			width: 3.5rem;
			display: flex;
			justify-content: center;
			align-items: center;
			padding-bottom: 1px;
			font-weight: 600;
		}

		&-today {
			background-color: $primaryColor;
			color: $primaryWhite;
			border-radius: 100rem;
		}

		&-events {
			position: absolute;
			top: 5rem;
			right: 0;
			width: 100%;
		}
	}
}

.fc-event {
	margin: 0;
}

.fc-day-sat, .fc-day-sun {
    background-color: $secondaryGreyLight;
}

.fc-timegrid-slot {
	cursor: cell;
}

.fc-daygrid-day-frame {
 	padding: .5rem;
	cursor: cell;
}

.fc-col-header-cell-cushion {
	text-transform: capitalize;
}

.fc-theme-standard .fc-scrollgrid {
	border-top: none;
}

.fc-col-header-cell {
	border: none;
}

.fc-daygrid-day {
	height: calc(100% / 6);
}

.fc-daygrid-event {
	border-radius: $borderRadius;
	margin: .3rem;
	background-color: $primaryWhite;
}

.MuiPickersYear-yearButton {
	font-size: 1.2rem !important;
}

.MuiPickersMonth-monthButton {
	font-size: 1.2rem !important;
}

.fc-list-day-cushion {
	background-color: $secondaryGrey !important;
}
