.listViewFile {
    &__icon {
        margin-right: .3rem;
        font-size: 1.6rem;
    }

    &__box {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    &__link {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-left: 2rem;
        color: $linkColor;
        cursor: pointer;
    }

    &__preview {
        width: 1.6rem;
        height: 1.6rem;
        font-size: 1.2rem;
        cursor: pointer;
    }

    &__name {
        margin-left: .5rem;
    }
}