.dashboard {
    height: calc(100vh - 8.5rem);

    &__content {
        width: 100%;
        display: grid;
	    // grid-template-columns: 1fr 1fr;
        grid-gap: 1.5rem;
	    height: 100%;
        overflow: auto;
        padding: 1.5rem;
    }

    &__item {
        height: 100%;
        background-color: $primaryWhite;
        box-shadow: $basicBoxShadow;
        border-radius: $borderRadius;
        overflow: hidden;
        z-index: 2;

        &-content {
            height: 100%;
            overflow: auto;

            & > .listView {
                height: calc(100% - 4rem);
            }

            & > .calendarWrapper {
                max-height: calc(100% - 4rem);
                width: 100%;

                & > .calendarContent {
                    display: block;
                    padding-top: 1.5rem;

                    & > .calendar {
                        width: 100%;
                        height: calc(100% - 3rem);
                        box-shadow: none;
                        margin-top: -.8rem;

                        & > div > div > div > table > thead > tr > th {
                            border-top: 1px solid #DDD;
                        }
                    }

                    & > .calendarList {
                        width: 100%;
                    }
                }

                & > .calendar__actionBox {
                    margin-bottom: .5rem;
                    padding: .5rem 1.5rem 0 1.5rem;

                    & > .calendar__actionButtons {
                        margin-right: 14rem;
                    }
                }
            }
        }

        &-delete {
            margin-left: auto;
        }

        &-label {
            margin-left: 1.6rem;
            font-size: 1.6rem;
            font-weight: 900;
        }

        & > .listView {
            box-shadow: none;
        }

        &-header {
            padding: 1rem;
            display: flex;
            // justify-content: space-between;
            align-items: center;
        }

        & > .report {
            height: calc(100% - 10rem);
        }
    }
}

.dashboardSettings {
    &__content {
        padding: 1rem;
    }
}