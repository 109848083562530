.infoBanner {
    position: absolute;
    bottom: 3rem;
    left: 3rem;
    width: 40rem;
    height: 6rem;
    border-radius: $borderRadius;
    z-index: 20;
    box-shadow: $secondaryBoxShadow;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &__warning {
        background-color: $warningOrange;
    }

    &__message {
        font-size: 1.3rem;
        color: $primaryWhite;
    }

    &__icon {
        font-size: 2.4rem;
        color: $primaryWhite;
        margin-right: 1rem;
    }
}