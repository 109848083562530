.detailHeader {
    height: 4rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &__status {
        height: 2.4rem;
        width: 2.4rem;
        border-radius: 10rem;
        display: block;
        float: left;
        margin-left: 1rem;
        background-color: $secondaryGreyDark;

        &-1 {
            background-color: $primaryGreen;
        }

        &-2 {
            background-color: $primaryYellow;
        }

        &-3 {
            background-color: $primaryRed;
        }
    }

    &__box {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    &__iconBox {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 4rem;
        height: 4rem;
        background-color: $activeColor;
        color: $primaryWhite;
        border-top-left-radius: .7rem;
    }

    &__icon {
        font-size: 1.8rem;
    }

    &__name {
        margin-left: 1rem;
        font-size: 1.5rem;
        font-weight: bold;
        color: $textColor;
    }
}
