.detail {
	width: 100%;
	height: calc(100% - 4.5rem);
	overflow: auto;
	// background-color: $primaryWhite;
	// border-radius: 0.7rem;
	// box-shadow: $basicBoxShadow;

	& > div > div > div > .MuiTab-root {
		font-size: 1.2rem !important;
	}
}

.detailToolTip {
	font-size: 1.2rem;
	background-color: $primaryRed;
	padding: 0.5rem;
	border-radius: 0.7rem;
}
