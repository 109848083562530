.actionButtons {
    width: 100%;
    height: 3rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1.5rem;

    &__divider {
        height: 80%;
        width: 2px;
        background-color: $primaryGreyLight;
        margin: 0 1rem;
    }

    &__skeleton {
        width: 13rem;
        border-radius: $borderRadius;
    }
}

.actionBox {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
}