.userSettings {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 1rem;
    overflow: auto;
    max-height: calc(100vh - 16.5rem);

    &__box {
        background-color: $primaryWhite;
        border-radius: $borderRadius;
        box-shadow: $basicBoxShadow;
    }

    @media (max-width: 650px) {
        flex-direction: column;
    }

    &__fields {
        width: 50%;
        @media (max-width: 650px) {
            width: 100%;
        }
    }
    &__photoBox {
        width: 20rem;
    }
    &__header {
        width: 100%;
        font-size: 2rem;
        color: $primaryGrey;
        padding: 1rem;
    }
}

.inputRow {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-column-gap: 1rem;
    width: 100%;
    margin-bottom: 1rem;

    &__empty {
        height: 1px;
    }

	@media (max-width: 1000px) {
		grid-template-columns: repeat(1, 1fr);
    }

    @media (max-width: 1000px) {
		& > .inputFieldBox:first-child {
            margin-bottom: 1rem;
        }
    }

    & > div {
        padding-bottom: .5rem;
    }
}