.listViewFavorite {
    & > span {
        color: $primaryYellow;
        font-size: 1.6rem;
        margin-left: .6rem;
        cursor: pointer;
        transition: font-size .2s;

        &:hover {
            font-size: 1.8rem;
        }
    }
}