.reports {
    height: 100%;
    display: flex;

    &__list {
        min-width: 36rem;
        width: 30%;
        height: 100%;
        box-shadow: $basicBoxShadow;
        background-color: $primaryWhite;
        margin-right: 1.5rem;
        border-radius: $borderRadius;

        &-icon {
            font-size: 2rem;
        }

        &-searchBox {
            height: 6rem;
            width: 100%;
            background-color: $secondaryGreyLight;
            display: flex;
            align-items: center;
            padding-left: 2rem;
            border-top-right-radius: $borderRadius;
            border-top-left-radius: $borderRadius;

            & > .searchBox__container > .searchBox {
                box-shadow: none;
            }
        }

        &-header {
            border-bottom: 2px solid $secondaryGrey;
            font-size: 1.4rem;
            font-weight: 600;
            text-transform: uppercase;
            padding: 1rem 0;
            color: $textColor;

        }

        &-content {
            padding: 1rem;
        }

        &-category {
            display: flex;
            align-items: center;
            padding: 2rem 0;
            border-bottom: 2px solid $secondaryGrey;
            cursor: pointer;
            color: $textColor;

            & > div:first-child {
                margin-right: 2rem;
                margin-left: 2rem;
                font-weight: 600;
            }

            & > div:last-child {
                font-weight: 600;
            }

            &:hover {
                background-color: $markHoverColorLight;
            }
        }

        &-categoryActive {
            background-color: $markHoverColorLight;

            & > .reports__list-icon {
                color: $activeColor;
            }
        }

        &-report {
            display: flex;
            align-items: center;
            padding: 1.5rem 0;
            border-bottom: 2px solid $secondaryGrey;
            cursor: pointer;
            color: $textColor;

            & > div:first-child {
                margin-right: 2rem;
                margin-left: 4rem;
                font-weight: 600;
            }

            & > div:last-child {
                font-weight: 600;
            }

            &:hover {
                background-color: $markHoverColorLight;
            }
        }

        &-reportActive {
            background-color: $markHoverColorLight;

            & > .reports__list-icon {
                color: $activeColor;
            }
        }
    }

    &__content {
        width: 70%;
        height: 100%;
        box-shadow: $basicBoxShadow;
        background-color: $primaryWhite;
        border-radius: $borderRadius;
    }
}