.buttonSmall {
    width: 3.2rem;
    height: 3.2rem;
    border-radius: $borderRadius;
    background-color: $primaryWhite;
    border: 1px solid $primaryGreyLight;
    cursor: pointer;
    transition: all .2s;

    &:hover {
        background-color: $activeColor;
        border: 1px solid $activeColor;

        & > span {
            color: $primaryWhite;
        }
    }

    & > span {
        color: $activeColor;
        font-size: 1.4rem;
    }

    &__disabled {
        &:hover {
            background-color: transparent;
            border: 1px solid #707070 !important;

            & > span {
                color: #707070;
            }
        }

        & > span {
            color: #707070;
            font-size: 1.4rem;
        }
    }
}