.listView {
	background-color: $primaryWhite;
	width: 100%;
	height: calc(100% - 4.5rem);
	border-radius: 0.7rem;
	padding: 1rem 1rem 0 1rem;
	box-shadow: $basicBoxShadow;
	color: $textColor;

	&__inPopup {
		height: 60vh;

		@media (max-width: 850px) {
            max-height: 55vh;
        }
	}

	&__row:nth-of-type(odd) {
		background-color: $secondaryGrey;
	}

	&__row:nth-of-type(even) {
		background-color: $secondaryGreyLight;
	}

	&__row {
		height: 3.2rem;
		font-size: 1.2rem;
		display: table-row;

		&:last-child > td {
			border-bottom: none;
		}

		&:hover {
            background-color: $markHoverColor;
        }
	}

	&__cell {
		padding: .4rem;
		overflow: hidden;
		//white-space: nowrap;
		text-overflow: ellipsis;
		display: table-cell;
		border-bottom: 1px solid $primaryGreyLight;

		&-checkbox {
			padding: 0;
			border-bottom: 1px solid $primaryGreyLight;
		}

		& > .currency {
			text-align: right;
		}

		& > .number {
			text-align: left;
		}

		& > .float {
			text-align: left;
		}
	}

	&__table {
		height: calc(100% - 3rem);
		overflow: scroll;

		&-box {
			overflow: scroll;
			display: table;
			width: 100%;
		}
	}
}

.hiddenOnLowRes {
	@media (max-width: 900px) {
		display: none !important;
	}
}
