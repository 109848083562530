.import {
    background-color: $primaryWhite;
	width: 100%;
	height: calc(100% - 4.5rem);
	border-radius: 0.7rem;
	padding: 1rem 1rem 0 1rem;
	box-shadow: $basicBoxShadow;
	color: $textColor;

	&__info {
		font-size: 1.4rem;
		font-weight: 600;
		margin-bottom: 1rem;
	}

	&__result {
		height: 32rem;
		overflow: auto;
		border: 1px solid $primaryGrey;
		padding: 1rem;
		border-radius: $borderRadius;
	}

	&__resultLabel {
		font-size: 1.4rem;
		margin-top: 3rem;
		margin-bottom: 1rem;
	}

	&__fileField {
		display: none;
	}

    &__buttons {
        height: 3rem;
		margin-bottom: 1.5rem;
    }

	&__settings {
		display: flex;
		margin-bottom: 3rem;

		& > div {
			margin-right: 5rem;
		}
	}

	&__identifyColumn {
		display: flex;
		align-items: center;
		font-size: 1.4rem;
		margin-bottom: 1rem;

		& > span {
			font-weight: 600;
			margin-left: .4rem;
		}
	}

	&__content {
		min-width: 100%;
		overflow: auto;
	}

	&__table {
		width: 100%;
		border-collapse: collapse;

		& > thead > tr > th {
			padding: .2rem;
			min-width: 20rem;
		}

		&-disabled {
			opacity: .5;
		}

		&-row {
			height: 3rem;

			& > td {
				padding: .2rem;
			}
		}

		&-row:nth-of-type(odd) {
			background-color: $secondaryGrey;
		}

		&-row:nth-of-type(even) {
			background-color: $secondaryGreyLight;
		}
	}
}