.setPasswordInput {
    width: 100%;

    & > p {
        font-size: 1.1rem !important;
    }
}

.setPasswordLabel {
    margin-right: 1rem;
    width: 14rem;
    margin-top: -1.6rem;
}

.setPasswordLabelAgain {
    margin-right: 1rem;
    width: 14rem;
    margin-bottom: -1.2rem;
}

.setPasswordSecondBox {
    margin-top: 0;
}

.setPasswordBox {
    width: 100%;
}
