.overview {
	width: 100%;
	padding: 1.5rem;
	height: 100%;
	margin-bottom: 4rem;

	&__content {
		display: grid;
		grid-template-columns: 1fr 4fr;
		grid-template-rows: 1fr 1fr;
		grid-gap: 1.5rem;
	}

	&__container {
		font-size: 1.5rem;
		background-color: $primaryWhite;
		border-bottom-left-radius: $borderRadius;
		border-bottom-right-radius: $borderRadius;
	}
}

.chartloader {
	position: absolute;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.chartdonut {
	&__main {
		position: relative;
		float: left;
		width: 23rem;
		padding: 1rem;
		text-align: center;
		border: 0px $primaryGreyLight solid;
		background-color: $primaryWhite;
		border-radius: $borderRadius;
		box-shadow: $basicBoxShadow;
		height: 32.2rem;
	}

	&__header {
		font-size: 1.7rem;
	}

	&__legend {
		min-height: 12.3rem;

		&__main {
			margin-top: 0.5rem;
			font-size: 1.2rem;
		}

		&__rec {
			float: left;
			width: 1.2rem;
			height: 1.2rem;
			clear: both;
		}

		&__iteml {
			float: left;
			margin-left: 1rem;
		}

		&__itemr {
			float: right;
		}
	}

	&__chart {
		position: absolute;
		width: 100%;
		left: 0rem;
		top: 23rem;
		font-size: 1.7rem;
	}
}

.chartcolumn {
	&__main {
		position: relative;
		float: left;
		padding: 1rem;
		text-align: center;
		border: 0px $primaryGreyLight solid;
		background-color: $primaryWhite;
		border-radius: $borderRadius;
		box-shadow: $basicBoxShadow;
		height: 32.2rem;
	}

	&__header {
		font-size: 1.7rem;
	}
}

.ln00 {
	clear: both;
	height: 0px;
}

.ln01 {
	clear: both;
	height: 1px;
}

.ln05 {
	clear: both;
	height: 0.5rem;
}
