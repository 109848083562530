 // Box shadow
$basicBoxShadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.15);
$secondaryBoxShadow: 0 5px 10px 0 rgba(0,0,0,.16);

$borderRadius: 7px;


:root {
    --link-color: #3098d4;
    --primary-color-light: #dbe2f0;
    --primary-color: #6f7aad;
    --primary-color-hover: #6171af;
    --primary-color-dark: #505f9e;
    --mark-hover-color: #e6f6ff;
    --mark-hover-color-light: #eef8ff;
    --third-blue: #282d33;
    --text-color: #333333;
    --primary-grey: #707070;
    --primary-grey-dark: #666666;
    --primary-grey-light: #c0c0c0;
    --secondary-grey-dark: #e7e7e7;
    --secondary-grey: #f0f0f0;
    --secondary-grey-light: #fafafa;
    --third-grey: #909090;
    --readonly-color: #D9D9D9;
    --primary-white: #ffffff;
    --dark-red: #860700;
    --primary-red: #dc3545;
    --light-red: #e45d56;
    --light-orange: #fa9551;
    --primary-orange: #ff8533;
    --primary-yellow: #f5c639;
    --dark-orange: #883600;
    --dark-green: #045704;
    --primary-green: #28a745;
    --light-green: #77d177;
    --primary-violet: #c700eb;
    --primary-black: #000;
    --warning-orange: #f39c12;
    --active-color: #6f7aad;
    --disable-color: #707070;
    --read-only-color: #dfdfdf;
    --special-action-color: #2dcdd3;
    --basic-box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.15);
    --secondary-box-shadow: 0 5px 10px 0 rgba(0,0,0,.16);
}

$darkOrange: var(--dark-orange);
$lightOrange: var(--light-orange);
$primaryOrange: var(--primary-orange);
$primaryRed: var(--primary-red);
$lightRed: var(--light-red);
$darkRed: var(--dark-red);
$primaryYellow: var(--primary-yellow);
$primaryGreen: var(--primary-green);
$lightGreen: var(--light-green);
$darkGreen: var(--dark-green);
$primaryViolet: var(--primary-violet);
$primaryBlack: var(--primary-black);
$primaryWhite: var(--primary-white);
$primaryGrey: var(--primary-grey);
$primaryGreyDark: var(--primary-grey-dark);
$primaryGreyLight: var(--primary-grey-light);
$secondaryGreyDark: var(--secondary-grey-dark);
$secondaryGrey: var(--secondary-grey);
$secondaryGreyLight: var(--secondary-grey-light);
$thirdGrey: var(--third-grey);
$readOnlyColor: var(--read-only-color);
$textColor: var(--text-color);
$thirdBlue: var(--third-blue);
$markHoverColor: var(--mark-hover-color);
$markHoverColorLight: var(--mark-hover-color-light);
$primaryColorDark: var(--primary-color-dark);
$primaryColor: var(--primary-color);
$primaryColorHover: var(--primary-color-hover);
$primaryColorLight: var(--primary-color-light);
$linkColor: var(--link-color);
$warningOrange: var(--warning-orange);
$basicBoxShadow: var(--basic-box-shadow);
$secondaryBoxShadow: var(--secondary-box-shadow);
$activeColor: var(--active-color);
$disableColor: var(--disable-color);
$readOnlyColor: var(--read-only-color);
$specialActionColor: var(--special-action-color);
