$fontSize: 1.4rem;

// Warning! The colors that are not set by variable dont replace by variable it is because login page should be light mode always
.login {
	height: 100%;
	width: 100%;
	background: #f0f0f0;
	font-size: 1.6rem;
	display: flex;
	justify-content: center;
	align-items: center;

	&__box {
		width: 40rem;
		height: 100%;
		padding: 2rem 2rem;
		height: 50rem;
		border-top-left-radius: $borderRadius;
		border-bottom-left-radius: $borderRadius;
		background: linear-gradient(287.56deg,#505f9e, #6f7aad 72.92%, #6f7aad 100%);
		//background-image: url('../../../public/images/login_background.jpg');
		//background-repeat: no-repeat;
		//background-position: center;

		@media (max-width: 900px) {
			display: none !important;
		}

		&-link {
			color: #FFF;
			text-decoration: none;
			font-size: 2rem;
			font-weight: 400;
		}

		&-linkBox {
			margin-top: 11.5rem;
		}

		&-header {
			font-size: 4.5rem;
			color: #FFF;
			font-weight: 500;
			margin-top: 12rem;
			text-align: center;
		}

		&-text {
			font-size: 1.8rem;
			color: #FFF;
			font-weight: 200;
			margin-left: 16rem;

			&--first {
				margin-top: 3rem;
			}
		}

		&-logo {
			height: 6rem;
		}
	}

	&__inputBox {
		height: 8rem;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: flex-start;
	}

	&__container {
		background-color: #FFF;
		width: 40rem;
		padding: 10rem 2rem;
		box-shadow: $basicBoxShadow;
		height: 50rem;
		border-top-right-radius: 0.7rem;
		border-bottom-right-radius: 0.7rem;

		@media (max-width: 900px) {
			border-radius: $borderRadius;
			margin: 0 2rem;
		}
	}

	&__link {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		font-size: $fontSize;
		margin-top: 2rem;
		text-decoration: none;
		color:#6f7aad;
		margin-right: 2rem;

		&:hover {
			text-decoration: underline;
		}
	}

	&__headerLogo {
		font-size: 3.2rem;
		text-transform: uppercase;
		margin-bottom: 4rem;
		text-align: center;
	}

	&__header {
		font-size: 3.2rem;
		text-transform: uppercase;
		margin-bottom: 2rem;
		margin-left: 2rem;
		color: #333;
	}

	&__label {
		width: 100%;
		font-size: 1.6rem;
		margin-bottom: 3rem;
		text-align: center;
		padding-left: 20px;
		padding-right: 20px;
	}

	&__logo {
		width: 22rem;
	}

	&__form {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: center;
		height: 75%;

		&-input > .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
			border-color: #707070 !important;
		}

		&-input--error > .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
			border-color: $primaryRed !important;
		}

		&-input {
			width: 90%;
			border-radius: 7px;
			& label {
				font-size: $fontSize;
				background-color: #FFF;
				padding-right: 0.8rem;
			}
			& > [data-shrink="true"] {
				color: #6f7aad !important;
			}

			& div {
				border-radius: 7px;
				height: 5rem !important;
				box-shadow: $basicBoxShadow;
			}
			& p {
				font-size: 1.2rem;
			}

			& input {
				font-size: $fontSize;
				border-radius: 7px;
			}
		}

		&-button {
			width: 90%;
			height: 4.7rem;
			background-color:#6f7aad !important;
			font-size: $fontSize !important;
			box-shadow: $basicBoxShadow;
		}
	}
}
