.ListViewLink {
    padding: .4rem;

    &__link {
        color: $linkColor !important;
        text-decoration: none;

        & > span {
            font-size: 1.2rem;
            margin-right: .5rem;
        }

        &:hover {
            text-decoration: underline;
            cursor: pointer;
        }

        &-disabled {
            color: $primaryGrey !important;
        }
    }
}