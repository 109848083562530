.buttonReport {
    height: 4rem;
    min-width: 4rem;
    background-color: $primaryColorLight;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    border-radius: $borderRadius;
    cursor: pointer;
    transition: all .2s;
    color: $primaryColor;
    padding: .8rem;
    font-weight: 600;
    font-size: 1.4rem;

    &:hover {
        background-color: $secondaryGreyDark;
    }

    &__withLabel {
        margin-right: 1rem;
    }

    & > span {
        font-size: 2rem;
    }
}
