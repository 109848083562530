.calendarViewSwitch {
    height: 100%;
    box-shadow: $basicBoxShadow;
    background-color: $primaryWhite;
    border-radius: $borderRadius;
    width: 20rem;
    overflow: hidden;

    &__button {
        height: 100%;
        outline: none;
        border: 0;
        width: 33.33%;
        color: $textColor;
        font-size: 1.2rem;
        background-color: $primaryWhite;
        cursor: pointer;
        transition: background-color .2s;

        &:hover {
            background-color: $primaryColor;
            color: #FFF;
        }

        &:first-child {
            border-right: 1px solid $primaryGreyLight;
        }

        &:last-child {
            border-left: 1px solid $primaryGreyLight;
        }

        &-active {
            color: #FFF;
            background-color: $primaryColor;
        }
    }
}