.config {
    width: 100%;
    height: calc(100vh - 12rem);
    background-color: $primaryWhite;
    box-shadow: $basicBoxShadow;
    padding: 1rem;
    border-radius: $borderRadius;

    &__label {
        font-weight: 600;
        font-size: 1.6rem;
        margin-bottom: 1rem;
        width: max-content;
        color: $textColor;
    }

    &__showPassword {
        width: 1.8rem;
        margin-top: 2.7rem;
        cursor: pointer;
        transition: all .2s;

        &:hover {
            color: $primaryBlack;
        }
    }

    &__termsAndConditions {
        grid-column: 1 / -1;
    }

    &__tab {
        font-size: 1.4rem !important;
        min-height: 5rem !important;
        padding: 0 1.2rem;
        text-transform: none !important;
        color: $textColor !important;

        &.Mui-selected {
            color: $linkColor !important;
        }

        & > span {
            font-size: 1.8rem;
        }
    }

    &__content {
        margin-top: 1rem;
        height: calc(100% - 6rem);
        overflow: auto;
    }

    &__form {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 1rem;

        @media (max-width: 1000px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width: 750px) {
			grid-template-columns: repeat(1, 1fr);
		}

        &-info {
            margin-left: 1rem;
            cursor: pointer;
            color: $primaryColor;
        }

        &-externalSystem {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-gap: 1rem;
            margin-bottom: 2rem;

            @media (max-width: 1000px) {
                grid-template-columns: repeat(2, 1fr);
            }

            @media (max-width: 750px) {
                grid-template-columns: repeat(1, 1fr);
            }
        }

        &-section {
            grid-column: 1 / -1;
            font-size: 1.4rem;
            color: $textColor;
            font-weight: 600;
            display: flex;
            align-items: center;
            padding-bottom: .4rem;
            // width: max-content;
            padding-left: .4rem;
            padding-right: .4rem;
            margin: 1.5rem 0;

            & > span {
                margin-right: 1rem;
            }
        }

        & > .switchWrapper {
            margin-top: auto;
        }
    }
}

.configPohoda {
    color: $textColor;

    &__warning {
        color: $primaryRed;
    }

    &__url {
        font-size: 2rem;
        color: $linkColor;
    }

    &__link {
        font-size: 1.6rem;
        color: $linkColor;
    }

    &__desc {
        font-size: 1.4rem;
    }

    &__icon {
        font-size: 2.2rem;
        color: $textColor;
        margin-left: 1rem;
        cursor: pointer;
    }

    &__box {
        margin-bottom: 1.5rem;
    }

    &__images {
        height: 30rem;
        margin-right: 1rem;
    }

    &__imageBox {
        margin-top: 1rem;
    }
}

.configCreditCheck {
    &__logo {
        margin-bottom: 1rem;
    }

    & > .config__form {
        margin-bottom: 1rem;
    }

    &__list {
        margin-left: 2rem;

        & > li {
            font-size: 1.4rem;
        }

        &-icon {
            font-size: 1.6rem;
        }
    }
}
