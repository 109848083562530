.addDetailLine {
    color: $textColor;

    &__container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__form {
        padding: 1rem;
        width: 80%;

        &-checkbox {
            transform: scale(1.4);
            width: 3.6rem;
        }

        &-input {
            width: 10rem;
        }
    }
    &__box {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 1rem;

        & > div > div, svg {
            color: $textColor;
        }

        & > div > div > input {
            color: $textColor;
        }

        & > .label {
            margin-right: 2rem;
            min-width: 20rem;
        }
    }
}
