.errorPage {
    background-color: #fefbf7;
    height: 100%;

    &__img {
        height: 60rem;
        top: 20%;
        left: 20%;
        margin-top: -15rem;
    }

    &__content {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;
    }

    &__box {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &__text {
        font-size: 3rem;
    }

    &__message {
        font-size: 2.4rem;
        color: $primaryRed;
        font-weight: 600;
        margin-top: 2rem;
    }

    &__goBack {
        height: 6rem;
        width: 24rem;
        border-radius: $borderRadius;
        background-color: $primaryWhite;
        color: $primaryColor;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 2.4rem;
        margin-top: 6rem;
        text-decoration: none;
        transition: all .2s;
        border: 2px solid $primaryColor;

        &:hover {
            color: $primaryWhite;
            background-color: $primaryColor;
        }
    }
}