.toastSlice {
    position: relative;
    width: 25rem;
    min-height: 5rem;
    padding: .5rem .5rem .5rem 1rem;
    color: $primaryWhite;
    border-radius: $borderRadius;
    overflow: hidden;
    text-overflow: ellipsis;
    box-shadow: $basicBoxShadow;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    background-color: $primaryColorDark;

    &__title {
        font-weight: 600;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 22rem;
    }

    &__description {
        max-width: 22rem;
        font-size: 1.2rem;
        margin-top: .2rem;
    }

    &__close {
        position: absolute;
        top: 1rem;
        right: .5rem;
        font-size: 2rem;
        margin-top: .3rem;
        margin-right: .5rem;
        transition: all .2s;
        transform: rotate(45deg);
        cursor: pointer;
    }

    &-info {
        border: 2px solid $primaryColorDark;
        background-color: $primaryColorDark;
        color: $primaryWhite;
    }

    &-success {
        border: 2px solid $darkGreen;
        background-color: $lightGreen;
        color: $darkGreen;
    }

    &-warning {
        border: 2px solid $darkOrange;
        background-color: $lightOrange;
        color: $darkOrange;
    }

    &-error {
        border: 2px solid $darkRed;
        background-color: $lightRed;
        color: $darkRed;
    }
}