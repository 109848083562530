.tooltip {
    background-color: $primaryColor;
    padding: .4rem .6rem;
    border-radius: $borderRadius;
    font-size: 1.2rem;
}

.MuiTooltip-arrow {
    color: $primaryColor !important;
}
