.subpanels {
    &__loader {
        margin-left: 50%;
        margin-top: 2rem;
    }

    & > .subpanel {
        margin-top: 1.5rem;
        box-shadow: $basicBoxShadow;
    }

    &__load {
        margin-top: 1.5rem;
    }
}
