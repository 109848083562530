.buttonBasic {
    height: 3rem;
    border-radius: $borderRadius;
    font-size: 1.4rem !important;
    background-color: $primaryWhite !important;
    box-shadow: $basicBoxShadow;
    color: $activeColor !important;
    text-transform: none !important;
    font-family: 'Cabin', 'Roboto', sans-serif !important;

    &__transparent {
        background-color: transparent !important;
        box-shadow: none;
    }

    @media (max-width: 900px) {
        min-width: 3rem !important;
        & > span {
            margin-right: 0;
        }
    }

    &__content {
        margin-bottom: -2px;
        margin-left: .5rem;
    }

    &__hideLabel > .buttonBasic__content {
        @media (max-width: 900px) {
            display: none !important;
            visibility: hidden;
        }
    }

    &:hover {
        color: #FFF !important;
    }

    &__icon {
        font-size: 1.6rem;

        @media (max-width: 900px) {
            margin-right: 0;
        }

        &-cross {
            transform: rotate(45deg);
        }
    }

    &__icon-margin {
        margin-right: 1rem;
    }

    &__green {
        &:hover {
            background-color: $primaryGreen !important;
        }
    }

    &__red {
        &:hover {
            background-color: $primaryRed !important;
        }
    }

    &__basic {
        &:hover {
            background-color: $primaryColor !important;
        }
    }

    &__disabled {
        background-color: $secondaryGrey !important;

        & > span {
            color: $disableColor !important;
        }
    }

    &:disabled {
        color: $primaryGrey !important;
        background-color: $primaryWhite !important;
    }
}

.reverseColorButton {
    background-color: $primaryColor !important;
    color: #FFF !important;
    transition: all .3s;

    &:hover {
        background-color: #FFF !important;
        color: $primaryColor !important;
    }
}
