.columnSort {
    &:hover {
        cursor: pointer;
    }
    &__ASC {
        font-size: 1.4rem;
        color: $primaryGreyLight;
        margin-bottom: -1.2rem;
    }

    &__DESC {
        font-size: 1.4rem;
        color: $primaryGreyLight;
    }

    &__active {
        color: $primaryGrey;
    }
}