:root {
    --loader-size: 10rem;
}

.appLoader {
    width: var(--loader-size);
    height: var(--loader-size);
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    border: 3px solid;
    border-color: $primaryColor $primaryColor transparent $primaryColor;
    box-sizing: border-box;
    animation: rotation 1.5s linear infinite;
    top: calc(50% - 4rem);
    left: 50%;
    transform: translateX(-50%) translateY(-50%) !important;
    z-index: 200;
    cursor: wait;

    &__overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        z-index: 199;
        cursor: wait;
    }
}

.appLoader::after,
.appLoader::before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border: 3px solid;
    border-color: transparent transparent $primaryColor $primaryColor;
    width: calc(var(--loader-size) - 15px);
    height: calc(var(--loader-size) - 15px);
    border-radius: 50%;
    box-sizing: border-box;
    animation: rotationBack 0.7s linear infinite;
    transform-origin: center center;
}

.appLoader::before {
    width: calc(var(--loader-size) - 30px);
    height: calc(var(--loader-size) - 30px);
    border-color: $primaryGrey $primaryGrey transparent transparent;
    animation: rotation 2s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes rotationBack {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(-360deg);
    }
}