.calendarList {
    background-color: $primaryWhite;
	width: calc(25% - 1.5rem);
    height: 100%;
	overflow: auto;
	border-radius: 0.7rem;
	padding: 1rem;
	box-shadow: $basicBoxShadow;

	@media (max-width: 900px) {
		display: none;
	}
}

.old-event {
	opacity: 0.4;
}