.textArea {
	& > div > textarea {
		font-size: 1.2rem !important;
		color: $textColor;
	}

	& > div {
		padding: 0.9rem 0.75rem 0.9rem 0.8rem;
	}
}
