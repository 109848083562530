$mainPageText: #292929;
$hoverText: #333333;
$mainPageBlue: #3C53F4;

.mainPage {
    width: 100%;
    height: 6rem;

    &__langs {
        font-size: 1.6rem;
        display: flex;
        align-items: center;
        margin-left: 5rem;
        z-index: 5;

        &-item {
            font-weight: 600;
            cursor: pointer;
            color: $mainPageText;
        }

        &-item.active {
            color: $mainPageBlue;
        }

        &-divider {
            height: 2.5rem;
            width: 2px;
            background-color: $mainPageText;
            margin: 0 1rem;
        }
    }

    &__logo {
        height: 5rem;
        cursor: pointer;
    }

    &__nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1.5rem 10%;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        z-index: 3;
        background-color: #FFF;

        @media (max-width: 1200px) {
			padding: 2.5rem;
		}
    }

    &__menu {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        @media (max-width: 900px) {
            display: none;
        }

        & > a {
            margin-left: 3rem;
            font-weight: 600;
            font-size: 1.6rem;
            text-decoration: none;
            color: $mainPageText;
            transition: color .2s;

            &:hover {
                color: $mainPageBlue;
            }
        }

        & > .mainPage__button {
            color: $mainPageBlue !important;
        }
    }

    &__hamburger {
        display: none;
        font-size: 5rem;
        color: $hoverText;

        @media (max-width: 900px) {
            display: block;
        }
    }

    &__button {
        border: 2px solid $mainPageBlue;
        padding: 1rem 2rem;
        transition: all .2s;
        border-radius: 1000px;
        color: $mainPageBlue;
        background-color: transparent;

        &:hover {
            background-color: $mainPageBlue;
            color: #FFF !important;
        }
    }

    &__button2 {
        padding: 1rem 2rem;
        transition: all .2s;
        border-radius: 1000px;
        background-color: transparent;

        &:hover {
            background-color: #FFF;
            border-color: #FFF !important;
        }
    }

    &__buttonLight {
        border: 2px solid #FFF;
        padding: 1rem 4rem;
        color: #FFF;
        z-index: 2;
        text-decoration: none;
        font-weight: 600;
        font-size: 2rem;
        transition: all .2s;
        border-radius: 1000px;

        @media (max-width: 1000px) {
            font-size: 1.4rem;
        }

        &:hover {
            background-color: #FFF;
            color: $mainPageText;
        }
    }

    &__home {
        margin-top: 5rem;
        margin-bottom: 15rem;

        @media (max-width: 750px) {
            margin-bottom: 5rem;
        }
    }

    &__header-big {
        font-size: 6rem;
        font-weight: 600;
        width: 50%;
        max-width: 60rem;

        @media (max-width: 1300px) {
            font-size: 4rem;
        }

        @media (max-width: 500px) {
            font-size: 3rem;
        }
    }

    &__appScreenBox {
        padding: 3rem;
        border-radius: 3rem;
        background-color: #FFF;
        width: fit-content;
        margin: 0 auto;
        border: 1px solid #3098d4;

        @media (max-width: 1300px) {
            & > img {
                height: 60vw;
            }
        }

        @media (max-width: 700px) {
            padding: 1.5rem;
        }
    }

    &__homeBox {
        position: relative;
        background-image: url('../../../public/images/main_page_bg_1.jpg');
    	background-repeat: no-repeat;
    	background-position: center;
        background-size: cover;
        background-attachment: fixed;
        height: 90rem;
        width: 100%;
        color: #FFF;
        margin-bottom: 40rem;

        @media (max-width: 900px) {
            height: 70rem;
            margin-bottom: 30rem;
        }

        @media (max-width: 700px) {
            height: 50rem;
            margin-bottom: 25rem;
        }

        &-box {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding-top: 10rem;
            width: 60%;
            margin: 0 auto 4rem auto;

            @media (max-width: 1300px) {
                width: 80%;
                margin: 0 auto 2rem auto;
            }
        }
    }

    &__packages {
        padding: 0 10%;
        margin-top: 10rem;

        &-box {
            display: flex;
            flex-wrap: wrap; /* Ensures the items will wrap to the next line if they don't fit */
            gap: 3rem; /* Optional: Adds some space between the items */
            justify-content: space-around; /* Centers items horizontally */
        }
    }

    &__package {
        width: 35rem;
        min-height: 40rem;
        border-top: 5px solid $mainPageBlue;
        border-bottom: 5px solid $mainPageBlue;
        background-color: #b5bdff;
        border-radius: 2rem;
        box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.15);
        transition: all .3s;
        text-align: center;
        padding: 2rem;

        &:hover {
            transform: scale(1.04);
            box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.15);
        }

        &-header {
            width: 100%;
            height: 8rem;
            // background-color: #888888;
            border-top-right-radius: 1.6rem;
            border-top-left-radius: 1.6rem;
            font-size: 3.5rem;
            color: #000;
            text-align: center;
            padding-top: 2rem;
            font-weight: 100;

            &--icon {
                margin-right: 1rem;
            }
        }

        &-price {
            font-size: 4rem;
            color: #000;
            width: 100%;
            text-align: center;
            font-weight: 500;
            margin-top: 2rem;
        }

        &-list {
            text-align: center;
            list-style-type: none;
            font-size: 2rem;
            margin-top: 3rem;
            color: #000;
            margin-bottom: 10rem;

            & > li {
                margin-top: 1rem;
            }

            &--icon {
                margin-right: 1rem;
            }
        }

        &-button {
            font-size: 1.8rem;
            cursor: pointer;
            text-align: center;
        }

        &-selected {
            font-size: 5rem;
            color: #28A745;
        }
    }
}

.pageInfo {
    padding: 0 30rem;

    @media (max-width: 1800px) {
        padding: 0 5rem;
    }

    &__header {
        color: $mainPageBlue;
        font-size: 6rem;

        @media (max-width: 1000px) {
            font-size: 4rem;
        }
    }

    &__box {
        margin-top: 4rem;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        @media (max-width: 1000px) {
            flex-direction: column;
            align-items: center;
        }

        & > img {
            @media (max-width: 1350px) {
                height: 60rem;
            }

            @media (max-width: 650px) {
                height: 40rem;
            }

            @media (max-width: 400px) {
                height: 30rem;
            }
        }
    }

    &__content {
        width: 40%;

        @media (max-width: 1000px) {
            width: 95%;
        }
    }

    &__textBox {
        margin-bottom: 3rem;

        & > h4 {
            font-size: 2.8rem;
            color: $mainPageText;
            margin-bottom: 1rem;

            @media (max-width: 500px) {
                font-size: 2.2rem;
            }
        }

        & > p {
            font-size: 1.8rem;
            color: $mainPageText;
            line-height: 3rem;

            @media (max-width: 500px) {
                font-size: 1.4rem;
                line-height: 2.2rem;
            }
        }
    }
}

.solution {
    background-image: url('../../../public/images/main_page_bg_2.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    height: 65rem;
    width: 100%;
    margin-top: 20rem;
    padding-top: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    @media (max-width: 1000px) {
        margin-top: 8rem;
    }

    &__box {
        width: 50rem;

        & > h1 {
            font-size: 6rem;
            color: #FFF;

            @media (max-width: 1000px) {
                font-size: 4rem;
            }
        }

        & > p {
            font-size: 1.6rem;
            color: #FFF;
            margin-top: 4rem;
            line-height: 3rem;
        }

        & > div {
            margin-top: 4rem;
        }
    }
}

.about {
    padding: 0 30rem;
    margin-top: 20rem;

    @media (max-width: 1500px) {
        padding: 0 5rem;
    }

    @media (max-width: 1000px) {
        margin-top: 8rem;
    }

    & > h1 {
        font-size: 6rem;
        color: $mainPageBlue;
        margin-bottom: 6rem;

        @media (max-width: 1000px) {
            font-size: 4rem;
        }

        @media (max-width: 500px) {
            margin-bottom: 2rem;
        }
    }

    &__box {
        display: flex;
        justify-content: space-between;

        @media (max-width: 1000px) {
            flex-direction: column;
        }
    }

    &__section {
        width: 30%;

        @media (max-width: 1000px) {
            width: 100%;
            margin-top: 3rem;
        }

        & > h4 {
            font-size: 2.8rem;
            color: $mainPageText;
            margin-bottom: 1rem;

            @media (max-width: 500px) {
                font-size: 2.2rem;
            }
        }

        & > p {
            font-size: 1.6rem;
            color: $mainPageText;
            line-height: 3rem;

            @media (max-width: 500px) {
                font-size: 1.4rem;
                line-height: 2.2rem;
            }
        }
    }
}

.reference {
    background-image: url('../../../public/images/main_page_bg_1.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    height: 40rem;
    width: 100%;
    margin-top: 20rem;
    padding-top: 10rem;

    @media (max-width: 1000px) {
        margin-top: 8rem;
    }

    @media (max-width: 900px) {
        height: 30rem;
    }

    &__header {
        // border-bottom: 1px solid #FFF;
        padding: 5rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 30rem;

        @media (max-width: 1300px) {
            padding: 2rem;
            padding-left: 15rem;
        }

        @media (max-width: 900px) {
            padding: 1rem;
            padding-left: 8%;
        }

        @media (max-width: 500px) {
            padding: 0;
        }

        & > h1 {
            font-size: 6rem;
            color: #FFF;
            margin: 0 2rem;

            @media (max-width: 1300px) {
                font-size: 4rem;
            }

            @media (max-width: 900px) {
                font-size: 3rem;
            }

            @media (max-width: 500px) {
                font-size: 2.2rem;
            }
        }
    }

    &__divider {
        width: 1px;
        background-color: #FFF;
        height: 15rem;
    }

    &__name {
        margin-top: 4rem !important;
        margin-left: 8rem !important;
        cursor: pointer;

        @media (max-width: 900px) {
            margin-left: 2rem !important;
        }
    }
}

.contact {
    padding: 0 30rem;
    margin-top: 10rem;
    text-align: center;

    @media (max-width: 1300px) {
        padding: 0 4rem;
    }

    & > h1 {
        font-size: 6rem;
        color: $mainPageBlue;

        @media (max-width: 1000px) {
            font-size: 4rem;
        }
    }

    &__form {
        margin-top: 6rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        &-conditions {
            transform: scale(1.8);
            margin-right: 2rem !important;
        }

        & > h4 {
            font-size: 2.8rem;
            color: $mainPageText;
            margin-bottom: 4rem;

            @media (max-width: 1000px) {
                font-size: 2.2rem;
            }
        }

        &-box {
            display: flex;
            justify-content: space-between;
            align-items: center;

            @media (max-width: 500px) {
                flex-direction: column;
            }
        }

        &-input {
            margin: 1rem;
            @media (max-width: 1000px) {
                margin: .2rem;
            }

            & > div {
                text-align: left;
                font-size: 1.8rem;
                color: $mainPageText;
                margin-bottom: 1rem;
                margin-left: 1rem;

                @media (max-width: 1000px) {
                    font-size: 1.4rem;
                }
            }

            & > input {
                height: 4rem;
                width: 30rem;
                border-radius: 1.2rem;
                outline: none;
                border: 1px solid $mainPageBlue;
                padding: 1rem;
                font-size: 1.6rem;
                color: $mainPageText;

                @media (max-width: 1000px) {
                    height: 3rem;
                    width: 20rem;
                }

                &:focus {
                    border: 2px solid $mainPageBlue;
                }
            }
        }

        &-error {
            font-size: 1.6rem;
            color: red;
            margin-top: 2rem;
        }

        &-message {
            font-size: 1.6rem;
            color: green;
            margin-top: 2rem;
        }

        &-button {
            align-self: flex-start;
            background-color: transparent;
            width: 30rem;
            margin-top: 2rem;
            font-size: 1.8rem;

            @media (max-width: 1000px) {
                font-size: 1.4rem;
                width: 20rem;
            }

            &:hover {
                cursor: pointer;
            }
        }

        &-captcha {
            margin-top: 2rem;
        }
    }
}

.required {
    color: red;
    margin-left: .4rem;
}

.inputError {
    border-color: red !important;
}

.footer {
    margin-top: 20rem;
    height: 1px;
    background-image: url('../../../public/images/main_page_bg_2.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    height: 20rem;
    color: #FFF;
    font-size: 2rem;
    padding: 2rem 30rem;
    display: flex;
    justify-content: space-between;
    font-weight: 600;

    @media (max-width: 1300px) {
        padding: 2rem 4rem;
    }


    @media (max-width: 500px) {
        font-size: 1.6rem;
        flex-direction: column;
        margin-top: 10rem;
    }

    &__contact {
        & > div {
            margin-bottom: 1rem;
        }
    }

    &__social > span {
        margin-left: 1.5rem;
        cursor: pointer;
    }

    &__copyright {
        font-size: 1.4rem;
        margin-top: 2rem;

        & > span {
            margin-right: .5rem;
        }
    }
}

.hamburgerMenu {
    position: absolute;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(10px);

    & > nav {
        padding: 5rem 0 0 5rem;
        display: flex;
        flex-direction: column;

        & > a {
            font-size: 3rem;
            color: #FFF;
            text-decoration: none;
            margin-bottom: 2rem;
            z-index: 6;
            cursor: pointer;
        }
    }

    &__close {
        font-size: 4rem;
        z-index: 6;
        color: #FFF;
        position: absolute;
        top: 5rem;
        right: 5rem;
        font-weight: 300;
    }

    &__langs {
        z-index: 5;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: absolute;
        left: 5rem;
        bottom: 5rem;

        & > div {
            color: #FFF;
            font-size: 3rem;
        }
    }

    &__divider {
        background-color: #FFF;
        width: 2px;
        height: 3rem;
        margin: 0 1rem;
    }
}

.hamburgerMenu::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 5;
}