.inputFieldBox {
	width: 100%;
	position: relative;

	& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
		border-color: $primaryGreyLight !important;
	}

	&__error-icon {
		position: absolute;
		top: 2.8rem;
		right: 1rem;
		color: $primaryRed;
		font-size: 1.5rem;
	}
}

.inputField {
	border-radius: 0.7rem;
	width: 100%;

	&__readonly {
		& > div {
			// border: 1px solid $primaryGrey !important;
			opacity: .6 !important;
		}

		& > div > input {
			border-color: $primaryGrey !important;
			color: rgba($textColor, .6) !important;
			-webkit-text-fill-color: rgba($textColor, .6) !important;
		}
	}

	&__error > .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
		border-color: $primaryRed !important;
	}

	& p {
		font-size: 1.1rem;
	}

	& > label {
		font-size: 1.4rem;
		background-color: $primaryWhite;
		padding-right: 0.8rem;
		color: rgba($primaryGrey, 0.6) !important;

		& > span {
			color: $primaryRed;
		}
	}

	& > div > input {
		padding: 0.75rem 0.75rem 0.75rem 0.8rem;
		font-size: 1.2rem;
		color: $textColor !important;
	}

	& > div {
		border-radius: 0.7rem;
	}
}

.MuiInputBase-adornedEnd {
	padding-right: 0 !important;
}

.simpleInput {
	width: 100%;
}
