.appError {
	width: 100%;
	height: 100%;
	padding: 3rem;
	border-radius: 0.7rem;
	background-color: $primaryWhite;
	box-shadow: $basicBoxShadow;
	font-size: 2.5rem;

	&__box {
		color: $primaryRed;
		display: flex;
		align-items: center;
		justify-content: flex-start;

		& > span {
			margin-right: 1rem;
		}

		& > p {
			font-weight: 400;
		}
	}

	&__line {
		height: 2px;
		width: 100%;
		background-color: $primaryGrey;
		margin: 1rem 0;
	}

	&__message {
		font-size: 1.8rem;
	}
}