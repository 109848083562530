.userMenu {
	background-color: $primaryWhite;
	z-index: 10;
	position: relative;
	top: 1.5rem;
	right: calc(26rem - 3rem);
	width: 26rem;
	border-radius: $borderRadius;
	box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.1);
	cursor: default;
	padding-bottom: 1rem;

	&__photo {
		width: 100%;
		border-right-width: 1px;
	}

	&__header {
		width: 100%;
		height: 4rem;
		background-color: $primaryColor;
		border-top-right-radius: 7px;
		border-top-left-radius: 7px;
	}

	&__themeSwitch {
		padding: .5rem;
		z-index: 2;
		position: absolute;
		top: .8rem;
		right: .8rem;
		border-radius: $borderRadius;
		cursor: pointer;
		transition: all 0.2s;
		width: 2.4rem;
		height: 2.4rem;
		display: flex;
		justify-content: center;
		align-items: center;

		&.iconfas-sun {
			background-color: $primaryGreyDark;
			color: #FFF;

			&:hover {
				background-color: #FFF;
				color: $primaryGreyDark;
			}
		}

		&.iconfas-moon {
			background-color: #FFF;
			color: $primaryGreyDark;

			&:hover {
				background-color: $primaryGreyDark;
				color: #FFF;
			}
		}
	}

	&__user {
		display: flex;
		align-items: center;
		height: 5rem;
		position: absolute;
		top: 1.4rem;
		left: 0;

		&-name {
			font-weight: bold;
			color: #FFF;
		}

		&-username {
			font-weight: bold;
			color: $primaryGrey;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			max-width: 15rem;
		}
	}

	&__box {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		flex-direction: column;
		height: 4.5rem;
		margin-left: 1rem;
	}

	&__profilePicture {
		margin: 1.5rem;
		margin-left: 2.75rem;
		color: $primaryWhite;
		background-color: $primaryGrey;
		height: 4.5rem;
		width: 4.5rem;
		border-radius: $borderRadius;
		border: 2px $primaryGrey solid;
		display: flex;
		justify-content: center;
		align-items: center;
		overflow: hidden;
	}

	&__actions {
		width: 100%;
		margin-top: 5rem;
		&-item {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			cursor: pointer;
			padding: 0.9rem 0.6rem;
			width: 100%;
			padding-left: 4.15rem;
			color: $primaryGrey;
			text-decoration: none;

			&:hover {
				background-color: $primaryColor;
				color: #FFF;
			}
		}

		&-icon {
			width: 25px;
			text-align: center;
		}

		&-text {
			margin-left: 1.5rem;
		}
	}
}
