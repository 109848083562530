.multiSelectField {
    &__select > div {
        padding: .4rem 2.4rem .5rem .4rem !important;

        &-empty > div {
            padding: .8rem 2.4rem .7rem .8rem !important;
        }
    }

    &__chip {
        height: 2.4rem !important;
        font-size: 1.2rem !important;
        background-color: $secondaryGrey !important;
        border-radius: .7rem !important;
        color: $textColor !important;

        &-icon {
            font-size: 1rem !important;
            color: $textColor !important;
        }
    }
}
