html[data-theme='dark'] {
	--link-color: #3098d4;
    --primary-color-light: #dbe2f0;
    --primary-color: #004166; // #004166
    --primary-color-hover: #003757;
    --primary-color-dark: #002d47;
    --mark-hover-color: #004166;
    --mark-hover-color-light: #005688;
    --third-blue: #282d33;
    --text-color: #d3d3d3;
    --primary-grey: #d3d3d3;
    --primary-grey-dark: #242424;
    --primary-grey-light: #d3d3d3;
    --secondary-grey-dark: #070606;
    --secondary-grey: #242424;
    --secondary-grey-light: #3b3b3b;
    --third-grey: #909090;
    --readonly-color: #909090;
    --primary-white: #3b3b3b;
    --dark-red: #860700;
    --primary-red: #dc3545;
    --light-red: #e45d56;
    --light-orange: #fa9551;
    --primary-orange: #ff8533;
    --primary-yellow: #f5c639;
    --dark-orange: #883600;
    --dark-green: #045704;
    --primary-green: #28a745;
    --light-green: #77d177;
    --primary-violet: #c700eb;
    --primary-black: #d3d3d3;
    --warning-orange: #f39c12;
    --active-color: #d3d3d3;
    --disable-color: #909090;
    --read-only-color: #909090;
    --special-action-color: #2dcdd3;
    --basic-box-shadow: 0px 0px 8px 1px rgba(255, 255, 255, 0.4);
    --secondary-box-shadow: 0 5px 10px 0 #ffffff29;
}