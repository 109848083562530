.calendarEventList {
    display: flex;
    flex-direction: column;
    margin-top: .2rem;

    &__header {
        font-weight: 600;
        margin-bottom: 1rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: $textColor !important;
        cursor: pointer;
    }

    &__icon {
        margin-right: .5rem;
        margin-top: - .5rem;
    }
}