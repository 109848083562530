.changes {
    min-height: 56rem;
    position: relative;

    &__table {
        width: 80rem;
        border-collapse: collapse;
        overflow: auto;

        & > thead > tr > th {
            text-align: left;
            padding-bottom: 1rem;
        }

        & > tbody > tr {
            height: 2.4rem;

            &:nth-of-type(odd) {
                background-color: $secondaryGrey;
            }

            &:nth-of-type(even) {
                background-color: $secondaryGreyLight;
            }
        }
    }

    &__pagination {
        position: absolute;
        right: 0;
        bottom: 0;

        & > .arrowChange {
            cursor: pointer;
            margin: 0 1rem;
            margin-bottom: .2rem;
        }

        & > .noArrow {
            width: 2rem;
        }
    }
}