.timeline {
	&__skeleton {
		width: calc(100% - 2rem);
		margin: 1rem;
		clip-path: polygon(98% 0%, 100% 50%, 98% 100%, 0% 100%, 2% 50%, 0% 0%);
	}
}

.arrows {
	display: block;
	width: 100%;
	margin: 0 auto;
	overflow: hidden;
	padding: 1rem;
	position: relative;

	@media (max-width: 800px) {
		display: none;
	}
}

.arrow {
	float: left;
	margin: 0;
	width: 32%;
	padding: 1.25rem .5rem;
	position: relative;
	text-align: center;
	min-height: 4.3rem;

	&__allowClick {
		&:hover {
			cursor: pointer;
		}
	}
}

.arrow:last-child:after {
	border: none;
	right: 0;
}

.backArrow {
    border-top: 2.1rem solid transparent;
	border-bottom: 2.1rem solid transparent;
	content: "";
	height: 0;
	position: absolute;
	left: 0;
	top: 0;
	width: 0;
	z-index: 2;
    background-color: transparent;

	@media (max-width: 1700px) {
		left: -1px;
	}
}

.frontArrow {
    border-top: 2.1rem solid transparent;
	border-bottom: 2.1rem solid transparent;
	content: "";
	height: 0;
	position: absolute;
	right: -17px;
	top: 0;
	width: 0;
	z-index: 2;
    background-color: transparent;

	@media (max-width: 1700px) {
		right: -16px;
	}
}

.arrow span {
	color: $primaryWhite;
	position: relative;
}
