.editableNumber {
    text-align: right;
    padding-right: 1rem;
    display: flex;
    // justify-content: flex-end;
    align-items: center;
    width: 100%;

    &__confirm {
        color: $primaryGreen;
        font-size: 1.4rem;
        cursor: pointer;
        width: 1.4rem;
        height: 1.4rem;
        opacity: 1;
        transition: .2s;
        margin-top: 1rem;
        margin-left: 1rem;
        font-weight: 900;
    }
    & > .MuiTextField-root {
        min-width: 10rem;
        margin-bottom: -1rem;

        & > div > input {
            margin-bottom: .4rem;
            padding-bottom: 0;
            font-size: 1.2rem;
        }
    }
}