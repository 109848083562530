.confirmPopup {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    &__text {
        font-size: 1.6rem;
        margin-top: 1rem;
        padding: .5rem 1.5rem 1.5rem 1.5rem;
        text-align: center;
    }

    &__footer {
        width: 100%;
        height: 4rem;
        padding: 1rem;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: .5rem;
        margin-bottom: 1rem;

        & > button {
            margin-left: 1.5rem;
        }

        @media (max-width: 700px) {
            width: 70%;
        }

        @media (max-width: 500px) {
            width: 80%;
            margin-top: .5rem;
        }
    }
}