.reportTable {
    width: 100%;

    &__header {
        height: 3rem;
        width: fit-content;
        background-color: $primaryGrey;
        padding: .5rem 1rem;
        color: $primaryWhite;
    }

    &__total {
        margin-bottom: 4rem;
    }

    &__table {
        width: 100%;
        border-top: 2px solid $primaryGrey;
        padding-top: .4rem;
        border-collapse: collapse;

        &-row:hover {
            background-color: $markHoverColor;
        }

        // &-row:nth-of-type(odd) {
        //     background-color: $secondaryGrey;
        // }

        // &-row:nth-of-type(even) {
        //     background-color: $secondaryGreyLight;
        // }

        &-headerCell {
            text-align: left;
            padding: .4rem 0;
            border-bottom: 1px solid $primaryGrey;
        }

        &-totalCell {
            border-top: 1px solid $primaryGrey;
            font-weight: 600;
            padding-top: .4rem;
        }

        &-divideCell {
            height: .4rem;
        }

        &-currency {
            text-align: right;
        }

        &-cell {
            padding: .4rem .2rem;
        }
    }
}