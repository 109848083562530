.recordsSelector {
    border: 1px solid $primaryGreyLight;

    &__item {
        display: flex;
        width: 100%;
        height: 3.5rem;
        padding: 0 .5rem;

        &:not(:last-child) {
            border-bottom: 1px solid $primaryGreyLight;
        }

        &-name {
            width: 90%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }

        &-checkbox {
            width: 10%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-left: 2.5rem;

            & > .basicCheckbox > span {
                padding: 0;
            }
        }
    }

    &__content {
        max-height: 30rem;
        min-height: 22rem;
        overflow: auto;
        padding: 1rem;
    }

    &__selectAll {
        color: #ACACAC;
    }
}
