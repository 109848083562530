.multiRelate {
    width: 100%;
    min-height: 3.2rem;
    border: 1px solid $primaryGreyLight;
    border-radius: $borderRadius;
    margin-top: 5px;
    padding: 0 .4rem .4rem .4rem;

    &__error {
        border-color: $primaryRed;
    }

    &__readonly {
        border-color: $readOnlyColor;
    }

    &__searchInput {
        width: 100%;
        position: sticky;

        & > div {
            & > input {
                color: $textColor;
                padding: .7rem 1.6rem .7rem .7rem;
            }

            & > fieldset {
                border-top: none;
                margin-top: -4px;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }
        }
    }

    &__box {
        position: absolute;
        width: 100%;
        background-color: $primaryWhite;
    }

    &__options {
        position: static;
        border-top: none;
    }

    &__chip {
        margin-left: .5rem !important;
        margin-top: .4rem !important;
    }
}