.listViewPopup {
    &__content {
        padding: 1rem;
        height: 100%;
        width: 60vw;

        @media (max-width: 850px) {
            width: 80vw;
        }

        & > .listView > .listView__table {
            padding: 0 0 .5rem .8rem;
        }
    }

    &__confirmButton {
        width: 100%;
        height: 4rem;
        font-size: 1.6rem;
        font-weight: bold;
        background-color: $primaryColor;
        cursor: pointer;
        border: none;
        border-radius: $borderRadius;
        transition: background-color .2s;
        color: $primaryWhite;
        margin-top: 1rem;

        & > span {
            margin-left: 1rem;
        }

        &:hover {
            background-color: $primaryColor;
        }
    }
}
