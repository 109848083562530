.detailForm {
	background-color: $primaryWhite;
	border-radius: 0.7rem;
	box-shadow: $basicBoxShadow;
	width: 100%;

	&__fields {
		width: 100%;
		padding: 1rem;
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		grid-column-gap: 1.2rem;
		min-width: 50vw;

		@media (max-width: 1000px) {
			grid-template-columns: repeat(2, 1fr);
		}

		@media (max-width: 750px) {
			grid-template-columns: repeat(1, 1fr);
		}
	}
}

.detailField {
	margin: 0.2rem 0;
	width: 100%;
	// max-width: 38rem;
	color: $primaryGrey;
	color: $textColor;
	display: flex;
	justify-content: flex-start;
	// align-items: flex-end;

	&__skeleton {
		display: flex;
		flex-direction: column;

		&-label {
			width: 5rem;
			margin: 0 0 .4rem 0;
			border-radius: $borderRadius;
		}

		&-field {
			width: 100%;
			margin-bottom: .5rem;
			border-radius: $borderRadius;
		}
	}

	& > button {
		align-self: flex-end;
		margin-left: .5rem;
	}

	&__hidden {
		display: none;
	}

	&__label {
		font-size: 1.2rem;
		margin-bottom: .4rem;
		margin-left: .5rem;
		height: 1.5rem;
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}

	&__required {
		color: $primaryRed;
		font-size: 1.4rem;
		font-weight: 600;
		margin-left: 0.5rem;
	}

	@media (min-width: 750px) {
		&.span2 {
			grid-column: span 2;
		}

		&.span3 {
			grid-column: span 3;
		}

		&.span4 {
			grid-column: span 4;
		}

		&.span5 {
			grid-column: span 5;
		}
	}
}

.emptyLine {
	margin: 0rem;
	grid-column: 1 / span 5;
	font-size: 1.4rem;
	color: $textColor;
	font-weight: bold;
	display: flex;
	justify-content: flex-start;
	align-items: center;

	& > button {
		margin-left: .5rem;
	}

	&__header {
		margin-top: 1rem;
		margin-left: 1rem;
	}

	&__myProfile {
		margin-bottom: 1rem;
		margin-left: .5rem;
	}

	@media (max-width: 1000px) {
		grid-column: 1 / span 2;
	}

	@media (max-width: 750px) {
		grid-column: 1 / span 1;
	}
}

.fieldIcon {
	font-size: 1.4rem;
	color: $primaryColor;
	margin-left: .4rem;
}
