.productVariant {
    overflow: hidden;
    margin-top: 1rem;

    &__box {
        box-shadow: $basicBoxShadow;
        margin-bottom: 1rem;
        background-color: $primaryWhite;
        border-radius: $borderRadius;
        overflow: hidden;
    }

    &__tabs {
        min-height: 3.6rem !important;

        & > div > div {
            overflow: auto;
        }

        & > div > div > button {
            color: $textColor;
            font-size: 1.4rem;
            text-transform: none;
            font-weight: bold;
            // margin: 4px 6px !important;
            padding: 0 !important;
            min-height: 3.6rem !important;
            display: flex;
            justify-content: space-between;
            padding-left: .6rem !important;
        }

        & > div > div > .MuiButtonBase-root.MuiTab-root.Mui-selected {
            color: $textColor;
        }
    }

    &__tabDivider {
        height: 1.8rem;
        width: 2px;
    }
}

::-webkit-scrollbar {
    height: .5rem !important;
}
