.usersTree {
	background-color: $primaryWhite;
	width: 100%;
	height: calc(100vh - 12rem);
	border-radius: 0.7rem;
	padding: 1rem;
	box-shadow: $basicBoxShadow;
	overflow: auto;

	&__company {
		text-align: center;

		&-name {
			height: 4rem;
			border-radius: $borderRadius;
			box-shadow: $basicBoxShadow;
			padding: 1rem 2rem;
			display: inline-block;
			font-size: 1.6rem;
			font-weight: 600;
			margin: 1.5rem 0 2rem 0;
		}
	}

	&__content {
		display: flex;
		flex-wrap: wrap; /* Ensures the items will wrap to the next line if they don't fit */
		gap: 1rem; /* Optional: Adds some space between the items */
		justify-content: space-around; /* Centers items horizontally */
	}

	&__item {
		box-shadow: $basicBoxShadow;
		padding: 1rem;
		border-radius: $borderRadius;

		&-name {
			font-size: 1.4rem !important;
			color: $linkColor;
			font-weight: bold;
			overflow: hidden;

			& > span {
				margin-right: 0.5rem;
			}
		}

		&-box {
			display: flex;
			justify-content: center;
			//align-items: flex-start;
			flex-direction: column;
		}

		&-contact {
			color: $linkColor;
			font-size: 1.2rem;
			font-weight: 300;
			text-decoration: none;
			margin-top: 0.4rem;

			& > span {
				margin-right: 0.5rem;
			}

			&:hover {
				text-decoration: underline;
			}

			&:last-child {
				margin-bottom: 1rem;
			}
		}

		&-10 {
			// client
			border: 2px solid rgba($primaryGreen, 0.5);
			width: 24rem;
			overflow: hidden;
			text-align: center;
			height: 8rem;
		}

		&-20 {
			// businessman
			border: 2px solid rgba($linkColor, 0.5);
			min-width: 24rem;
			max-width: 52rem;
			text-align: center;

			& > .usersTree__children {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-around;
				gap: 1rem;
			}
		}

		&-30 {
			// manager
			border: 2px solid rgba($primaryViolet, 0.5);
			min-width: 24rem;
			text-align: center;
			text-align: center;

			& > .usersTree__children {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-around;
				gap: 1rem;
			}
		}
	}
}
