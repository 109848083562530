.basicCheckbox {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.2rem;

    &__label {
        margin-left: 1rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &:hover {
            cursor: pointer;
        }
    }

    &__checkbox-small {
        transform: scale(1.2) ;
    }

    &__checkbox-standardSize {
        transform: scale(1.4);
    }

    &__checkbox-blue {
        color: $primaryColor !important;
    }

    &__checkbox-standardColor {
        color: $primaryGrey !important;
    }
}