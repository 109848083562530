.popover {
    &__item {
        padding: .5rem;
        cursor: pointer;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background-color: $primaryWhite;
        color: $textColor;

        &:hover {
            background-color: $secondaryGreyLight;
        }

        &-icon {
            margin-right: 1rem;
            color: $activeColor;
        }

        &-disabled {
            cursor: not-allowed;
            color: $primaryGreyLight;

            & > .popover__item-icon {
                color: $primaryGreyLight;
            }

            &:hover {
                background-color: $primaryWhite;
            }
        }
    }
}