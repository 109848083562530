.listViewEnum {
    padding: 0 .2rem;
    &__box {
        height: 100%;
        color: $primaryWhite;
        border-radius: .7rem;
        color: $textColor;
        display: flex;
        align-items: center;
    }

    &__timeline {
        color: #FFF;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 2rem;
    }
}